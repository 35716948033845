import React, { ReactFragment } from 'react'
import { Box, Link } from 'rebass'
import { Text, theme, StandardPadding, Pill, NewImage } from '../../shared'

export const FEATURES_SERVICE_LIST = [
  {
    iconSrc: '/static/images/product/features/chat-icon.svg',
    iconDesc: 'chat bubble icon',
    title: 'In-app live chat',
    text: `Send us an instant message \n in the app`,
  },
  {
    iconSrc: '/static/images/product/features/phone-icon.svg',
    iconDesc: 'phone icon',
    title: 'Tell us when to call you',
    text: `Schedule a call at a time that \n suits you in the app`,
  },
  {
    iconSrc: '/static/images/product/features/email-icon.svg',
    iconDesc: 'paper plane icon',
    title: 'Send an email',
    text: (
      <Text>
        Email us at <br />
        <Link sx={{ color: theme.colors.gold100 }} href="mailto:support@northone.com">
          support@northone.com
        </Link>
      </Text>
    ),
  },
]

interface ISupportIconProps {
  iconSrc: string
  iconDesc: string
  title: string
  text: string | ReactFragment
}

export const SupportIcon = ({ iconSrc, iconDesc, title, text }: ISupportIconProps) => (
  <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
    <NewImage
      sx={{
        height: '5rem',
        marginTop: theme.halfVerticalSectionSpacing,
        marginBottom: theme.verticalElementSpacing,
      }}
      src={iconSrc}
      alt={iconDesc}
    />
    <Text tag="h3" sx={{ textAlign: 'center', color: theme.colors.blackPrimary, marginBottom: '0.5rem' }}>
      {title}
    </Text>
    <Text style={{ textAlign: 'center' }}>{text}</Text>
  </Box>
)

export const ServiceHours = (): JSX.Element => (
  <StandardPadding>
    <Box
      sx={{
        width: theme.contentMaximumWidth,
      }}
    >
      <Pill
        textColor={theme.colors.blackPrimary}
        subtitleColor={theme.colors.mediumGrey}
        title={`Customer Service Hours`}
        subtitle={`NorthOne customer care specialists in your area are available every day between 8 am-1 am ET (5 am-10 pm PT).`}
        imageSrc="/static/images/our-customers/startups/startup-support.png"
        mobileImageSrc="/static/images/our-customers/startups/startup-support-mobile.png"
        imageDesc="Live Chat text conversation between a Customer and a NorthOne Support specialist over a lost card"
        backgroundColor={theme.colors.lightGrey}
        customParagraphMaxWidth={['300px']}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-around', flexDirection: ['column', 'column', 'row'] }}>
        {FEATURES_SERVICE_LIST.map((item, index) => (
          <SupportIcon key={index} {...item} />
        ))}
      </Box>
    </Box>
  </StandardPadding>
)
