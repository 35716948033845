import React from 'react'
import { StandardPadding, Text, theme, TextHero } from '../../shared'
import { Box, Image } from 'rebass'

interface ITableCellProps {
  imageSrc: string
  imageHeight?: number | number[]
  imageWidth?: number | number[]
  backgroundColor?: string
  alt: string
}

interface ITextTableCellProps {
  text?: string
}

const criteriaText = {
  onlineSignup: '100% online sign up in 3 minutes',
  mobileApp: 'Mobile app built for SMBs',
  teamLogins: 'Separate logins for every team member',
  support247: '24/7 in-app support',
  accountingIntegrations: 'Free accounting integrations',
  atms: 'ATM withdrawals & deposits',
}

type Criteria = keyof typeof criteriaText

interface IBank {
  name: string
  image: string
  imageHeight?: number[] | number
  imageWidth?: number[] | number
  alt: string
  data: { [k in Criteria]: boolean }
  backgroundColor?: string
}

const BANKS: IBank[] = [
  {
    name: 'north-one',
    image: '/static/images/comparison-table/north-one-logo.png',
    imageHeight: [9, 23, 23],
    imageWidth: [38, 94, 94],
    alt: 'North One Business Logo',
    data: {
      onlineSignup: true,
      mobileApp: true,
      teamLogins: true,
      support247: true,
      accountingIntegrations: true,
      atms: true,
    },
    backgroundColor: theme.colors.green80,
  },
  {
    name: 'chase',
    image: '/static/images/comparison-table/chase-logo.png',
    imageHeight: [6.5, 13, 13],
    imageWidth: [35.5, 71, 71],
    alt: 'Chase Bank Logo',
    data: {
      onlineSignup: false,
      mobileApp: false,
      teamLogins: false,
      support247: false,
      accountingIntegrations: false,
      atms: true,
    },
  },
  {
    name: 'bankOfAmerica',
    image: '/static/images/comparison-table/bank-of-america-logo.png',
    imageHeight: [18, 36, 36],
    imageWidth: [40, 80, 80],
    alt: 'Bank of America Logo',
    data: {
      onlineSignup: false,
      mobileApp: false,
      teamLogins: false,
      support247: false,
      accountingIntegrations: false,
      atms: true,
    },
  },
  {
    name: 'wellsFargo',
    image: '/static/images/comparison-table/wells-fargo-logo.png',
    imageHeight: [30, 45, 45],
    imageWidth: [30, 45, 45],
    alt: 'Wells Fargo Bank Logo',
    data: {
      onlineSignup: false,
      mobileApp: false,
      teamLogins: false,
      support247: false,
      accountingIntegrations: true,
      atms: true,
    },
  },
  {
    name: 'usBank',
    image: '/static/images/comparison-table/us-bank-logo.png',
    imageHeight: [12.5, 25, 25],
    imageWidth: [36, 72, 72],
    alt: 'US Bank Logo',
    data: {
      onlineSignup: false,
      mobileApp: false,
      teamLogins: false,
      support247: false,
      accountingIntegrations: true,
      atms: true,
    },
  },
  {
    name: 'pncBank',
    image: '/static/images/comparison-table/pnc-logo.png',
    imageHeight: [35.5, 71, 71],
    imageWidth: [35.5, 71, 71],
    alt: 'PNC Bank Logo',
    data: {
      onlineSignup: false,
      mobileApp: false,
      teamLogins: false,
      support247: false,
      accountingIntegrations: true,
      atms: true,
    },
  },
]

const TEXT_CELL_WIDTH = '150px'
const DESKTOP_TEXT_CELL_WIDTH = '175px'
const CELL_HEIGHT = '50px'

const CELL_WIDTH = 120
const TABLET_WIDTH = 60
const MOBILE_CELL_WIDTH = 40

export const TextTableCell = ({ text }: ITextTableCellProps) => (
  <Box
    sx={{
      width: [TEXT_CELL_WIDTH, TEXT_CELL_WIDTH, DESKTOP_TEXT_CELL_WIDTH],
      height: CELL_HEIGHT,
      border: `0px solid ${theme.colors.borderGrey}`,
      borderBottomWidth: text ? 1 : 0,
      display: 'flex',
      alignItems: 'center',
      paddingX: '15px',
    }}
  >
    <Text
      sx={{
        fontSize: [theme.fontStyles[0].fontSize - 2, theme.fontStyles[0].fontSize - 1, theme.fontStyles[1].fontSize],
      }}
    >
      {text}
    </Text>
  </Box>
)

const TableCell = ({ backgroundColor, imageSrc, imageWidth, imageHeight, alt }: ITableCellProps) => (
  <Box
    sx={{
      height: CELL_HEIGHT,
      border: `0px solid ${backgroundColor ? 'transparent' : theme.colors.borderGrey}`,
      borderBottomWidth: 1,
      display: 'flex',
      alignItems: 'center',
      paddingX: ['5px', '15px'],
      backgroundColor: backgroundColor || 'transparent',
      justifyContent: 'center',
    }}
  >
    <Image alt={alt} src={imageSrc} height={imageHeight} width={imageWidth} />
  </Box>
)

const getObjectKeys = <O extends object>(obj: O) => Object.keys(obj) as Array<keyof O>

const BankColumn = ({ backgroundColor, image, imageHeight, imageWidth, alt, data, name }: IBank) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: [MOBILE_CELL_WIDTH, TABLET_WIDTH, CELL_WIDTH],
      flexShrink: 0,
    }}
  >
    <TableCell
      backgroundColor={backgroundColor}
      imageSrc={image}
      alt={alt}
      imageHeight={imageHeight}
      imageWidth={imageWidth}
    />
    {getObjectKeys(data).map((criteria, _index) => (
      <TableCell
        key={_index}
        backgroundColor={backgroundColor}
        alt={data[criteria] ? 'Check' : 'X'}
        imageSrc={
          name === 'north-one'
            ? '/static/images/comparison-table/north-one-check.svg'
            : data[criteria]
            ? '/static/images/comparison-table/check.svg'
            : '/static/images/comparison-table/x.svg'
        }
      />
    ))}
  </Box>
)

export const FeatureComparisonTable = () => (
  <>
    <StandardPadding>
      <TextHero titleTag="h2" title={'NorthOne is built to save you __time and money__'} />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'inline-block',
            border: `2px solid ${theme.colors.borderGrey}`,
            overflow: 'hidden',
            borderRadius: '12px',
          }}
        >
          <Box sx={{ float: 'left' }}>
            <TextTableCell text={''} />
            {Object.values(criteriaText).map((text, index) => (
              <TextTableCell key={index} text={text} />
            ))}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <BankColumn {...BANKS[0]} />
            <Box
              sx={{
                maxWidth: CELL_WIDTH * BANKS.length - 1,
                display: 'flex',
                '@media screen and (max-width: 64em)': {
                  overflow: 'scroll',
                },
              }}
            >
              {BANKS.slice(1).map((bank, index) => (
                <BankColumn key={index} {...bank} />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </StandardPadding>
  </>
)
