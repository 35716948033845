import React from 'react'
import { Button, FlexRowLayout, theme as N1Theme } from '@northone/ui'
import { Button as RButton, Image } from 'rebass'
import { theme, Text } from '../../shared'

interface INavBarButtonProps {
  text: string
  hoverStateColor?: string
  iconSrc: string
  marginRight?: number
  onClick: () => void
  customBackgroundColor?: string
  customWidth?: number
  customHeight?: number
  customPadding?: number
  customMargin?: number
}
interface IMobileLearnMoreButtonProps {
  onClick: () => void
}

export const NavBarButton = ({
  text,
  hoverStateColor,
  iconSrc,
  marginRight,
  onClick,
  customBackgroundColor,
  customWidth,
  customHeight,
  customPadding,
  customMargin,
}: INavBarButtonProps) => (
  <Button
    textTag="body-bold"
    sx={{
      marginRight,
      backgroundColor: customBackgroundColor || N1Theme.colors.ui7,
      borderRadius: 0,
    }}
    style={{
      enabledStyle: {
        border: 'none',
        textColor: theme.colors.blackPrimary,
        ':hover': { backgroundColor: hoverStateColor || N1Theme.colors.ui6 },
        width: customWidth || undefined,
        height: customHeight || undefined,
        margin: customMargin || undefined,
        padding: customPadding || undefined,
      },
      disabledStyle: {
        opacity: 0,
        textColor: 'transparent',
      },
    }}
    iconSrc={iconSrc}
    onClick={onClick}
  >
    <Text tag={`p`} sx={{ fontFamily: [theme.fonts.grtskPetaSemiBold], color: theme.colors.blackPrimary }}>
      {text}
    </Text>
  </Button>
)

export const MobileLearnMoreButton = ({ onClick }: IMobileLearnMoreButtonProps) => (
  <RButton
    onClick={onClick}
    sx={{
      outline: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      paddingRight: 0,
    }}
  >
    <FlexRowLayout>
      <Text
        tag={'p'}
        sx={{
          color: theme.colors.blackPrimary,
          fontFamily: theme.fonts.grtskPetaSemiBold,
          fontSize: '16px',
          lineHeight: '22px',
          letterSpacing: '-0.16px',
        }}
      >
        Learn More
      </Text>
      <Image
        src="/navbar/hamburger-mobile.svg"
        sx={{ paddingLeft: 10, height: 'auto', width: 'auto' }}
        alt="hamburger logo"
      />
    </FlexRowLayout>
  </RButton>
)
