import React, { useState } from 'react'
import { Box } from 'rebass'
import { Text, theme, NewImage, StandardPadding } from '../../shared'

export const SBALogos: React.FC<{}> = () => {
  const [activeIndex, setActiveIndex] = useState<null | number>(null)

  const LOGOS = [
    {
      greySrc: '/forbes-logo.svg',
      blackSrc: '/forbes-logo-black-hover.svg',
      alt: 'Forbes logo',
      sx: { width: 129 },
    },
    {
      greySrc: '/new-york-times-logo.svg',
      blackSrc: '/new-york-times-logo-black-hover.svg',
      alt: 'New York Times logo',
      sx: { width: '148px', marginLeft: [45, 45, 128], marginRight: [27, 27, 108] },
    },
    {
      greySrc: '/fortune-logo.svg',
      blackSrc: '/fortune-logo-black-hover.svg',
      alt: 'Fortune logo',
      sx: { width: 152 },
    },
  ]
  return (
    <StandardPadding
      sx={{
        backgroundColor: theme.colors.gold10,
        paddingTop: 28,
        paddingBottom: 36,
        paddingX: [92, 92, 300],
      }}
    >
      <Text sx={{ ...theme.fontstyles.eyeBrowText, marginBottom: 50 }}>AS FEATURED IN</Text>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {LOGOS.map((logo, index) => {
          const { greySrc, blackSrc, alt, sx } = logo
          return (
            <NewImage
              key={index}
              src={index === activeIndex ? blackSrc : greySrc}
              alt={alt}
              sx={sx}
              onMouseEnter={() => setActiveIndex(index)}
              onMouseLeave={() => setActiveIndex(null)}
            />
          )
        })}
      </Box>
    </StandardPadding>
  )
}
