import React from 'react'
import { Box, Image, SxStyleProp } from 'rebass'
import { Text, theme } from '../../../shared'

interface IBulletProps {
  image: string
  imageDesc: string
  text: string
  sx?: SxStyleProp
  imageSx?: SxStyleProp
  textStyle?: SxStyleProp
}

export const Bullet = ({ image, imageDesc, text, sx, imageSx, textStyle }: IBulletProps): JSX.Element => (
  <Box sx={{ display: 'flex', ...(sx as SxStyleProp) }}>
    <Box
      sx={{
        marginTop: '3px',
      }}
    >
      <Image style={{ ...(imageSx as React.CSSProperties) }} src={image} alt={imageDesc} />
    </Box>
    <Text
      sx={{
        paddingLeft: '10px',
        width: '100%',
        fontSize: theme.fontStyles[1].fontSize,
        ...textStyle,
      }}
    >
      {text}
    </Text>
  </Box>
)
