import React from 'react'
import { theme, StandardPadding } from '../../shared'
import { Box } from 'rebass'

interface IPartnershipsBlockProps {
  imageJSX: JSX.Element
  contentJSX: JSX.Element
  height: number
  reverse?: boolean
  backgroundColor?: string
}

export const PartnershipsBlock = ({
  imageJSX,
  contentJSX,
  height,
  reverse = false,
  backgroundColor = theme.colors.gold30,
}: IPartnershipsBlockProps) => (
  <StandardPadding>
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        flexDirection: ['column', 'column', 'column', 'row'],
        justifyContent: ['center', 'center', 'center', reverse ? 'flex-end' : 'flex-start'], //
        alignItems: 'center',
        backgroundColor,
        width: theme.contentMaximumWidth,
        borderRadius: '4px',
        paddingTop: [0, 0, 0, 40],
        paddingBottom: 30,
        minHeight: [null, null, height],
        ...(reverse ? { paddingRight: [0, 0, 0, 26] } : { paddingLeft: [0, 0, 0, 68] }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: reverse ? ['flex-start', 'center'] : ['flex-end', 'center'],
          width: ['100%', '100%', '100%', reverse ? 400 : 0],
        }}
      >
        {imageJSX}
      </Box>
      {contentJSX}
    </Box>
  </StandardPadding>
)
