import React, { useState } from 'react'
import { Text, theme } from '../../shared'
import { Box, SxStyleProp, Image, Link } from 'rebass'

interface IIconsListProps {
  title: string
  iconSrc: string
  link: string
  alt: string
}
export interface IIconsRowProps {
  iconList: IIconsListProps[]
  containerStyles?: SxStyleProp
  hasHoverEffect?: boolean
}

export const IconsRow = ({ iconList, containerStyles, hasHoverEffect = false }: IIconsRowProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <Box sx={{ display: 'flex', ...containerStyles }}>
      {iconList.map((icon: IIconsListProps, idx: number) => {
        const { title, iconSrc, link, alt } = icon
        return (
          <Link href={link} key={idx}>
            <Box
              onMouseEnter={() => setActiveIndex(idx)}
              sx={{
                marginRight: ['5px', '10px'],
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Image
                sx={{
                  width: ['50px', '70px', '50px', '55px', '70px'],
                  height: 'auto',
                  alignSelf: 'center',
                  ...(idx !== activeIndex && hasHoverEffect && { filter: 'grayscale(1)', opacity: '0.5' }),
                }}
                src={iconSrc}
                alt={alt}
              />
              <Text
                sx={{
                  color: idx === activeIndex && hasHoverEffect ? theme.colors.blue : theme.colors.mediumGrey,
                  fontSize: [12, 12, 12, 12, 12],
                  display: ['none', 'flex'],
                }}
              >
                {title}
              </Text>
            </Box>
          </Link>
        )
      })}
    </Box>
  )
}
