import React from 'react'
import { BaseLayout, TextBlock, TextHero, NewImage, theme } from '../../../shared'
import { fireWebsiteSignupEvent } from '../../../utils'
import { ONBOARDING_URL } from '../../../utils/urls'

export const StartupLanding = (): JSX.Element => (
  <>
    <BaseLayout
      imageComponent={
        <NewImage
          src="/static/images/our-customers/startups/startup-landing.png"
          alt="NorthOne Insight feature for showing revenue and expenses"
          style={{ marginLeft: '20px' }}
        />
      }
      bodyComponent={
        <TextBlock
          title={`Banking for __Startups__`}
          titleTag="h1"
          paragraph={
            'NorthOne Deposit Accounts are built for startups. Our built-in features make banking a breeze so you can focus on scaling your business.'
          }
          paragraphStyle={{ ...theme.fontStyles[3] }}
          ctaButton={{
            text: 'Apply for an account',
            type: 'blackPrimary',
            url: ONBOARDING_URL,
            onClick: fireWebsiteSignupEvent,
            buttonSx: {
              width: [285, 285, 235],
            },
          }}
        />
      }
    />
    <TextHero
      withPadding
      paragraphTag="h3Serif"
      containerSx={{ maxWidth: 746 }}
      paragraph={'Take care of business with a smart digital Deposit Account. No branches. No paperwork. No headaches.'}
      paragraphColor={theme.colors.gold100}
      customHorizontalPadding={[
        theme.horizontalPadding[0],
        theme.horizontalPadding[1],
        theme.horizontalPadding[2],
        '0',
      ]}
      textHeroPaddingBottom={['0px', '0px', '40px']}
    />
  </>
)
