import React, { useState } from 'react'
import Carousel from 'nuka-carousel'
import { TestimonialCard, TESTIMONIALS_CONTENT, TestimonialPagination } from '../../components'
import { Box } from 'rebass'
import { theme } from '../../shared'

export const TestimonialCarousel = (): JSX.Element => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const handleButtonClick = (index: number) => {
    setCurrentSlideIndex(index)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: theme.imageHeights,
      }}
    >
      <Carousel
        speed={3}
        autoplay={true}
        wrapAround
        pauseOnHover={true}
        swiping={true}
        slideIndex={currentSlideIndex}
        afterSlide={slideIndex => setCurrentSlideIndex(slideIndex)}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        renderBottomCenterControls={null}
      >
        {TESTIMONIALS_CONTENT.map((item, idx) => (
          <TestimonialCard
            key={idx}
            imgPath={item.imgPath}
            imageDesc={item.imageDesc}
            title={item.title}
            text={item.text}
            name={item.name}
            occupation={item.occupation}
          />
        ))}
      </Carousel>
      <TestimonialPagination currentSlideIndex={currentSlideIndex} handleButtonClick={handleButtonClick} />
    </Box>
  )
}
