import React, { useRef } from 'react'
import { Box } from 'rebass'
import { motion, AnimatePresence } from 'framer-motion'

import { theme } from '@northone/ui'
import { ExpansionNavMobile } from './mobile'
import { ExpansionNavDesktop } from './desktop'
import { useClickInside } from '../utils/hooks'

interface IAnimatedExpansionNavContainerProps {
  shouldShowOverlay: boolean
  setShouldShowOverlay: (shouldShowOverlay: boolean) => void
}

export const AnimatedExpansionNavContainer = ({
  shouldShowOverlay,
  setShouldShowOverlay,
}: IAnimatedExpansionNavContainerProps) => {
  const overlayRef = useRef<HTMLDivElement>(null)
  useClickInside([overlayRef], () => setShouldShowOverlay(false))

  return (
    <AnimatePresence>
      {shouldShowOverlay && (
        <motion.div
          layout
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 100,
          }}
        >
          <Box
            sx={{
              display: ['none', 'none', 'flex'],
            }}
          >
            <ExpansionNavDesktop onCloseClick={() => setShouldShowOverlay(!shouldShowOverlay)} />
          </Box>
          <Box
            sx={{
              display: ['flex', 'flex', 'none'],
            }}
          >
            <ExpansionNavMobile onCloseClick={() => setShouldShowOverlay(!shouldShowOverlay)} />
          </Box>
          <motion.div
            layout
            style={{
              position: 'inherit',
              top: 0,
              left: 0,
              zIndex: -1,
              backgroundColor: theme.colors.ui7,
              width: '100vw',
              height: '100vh',
            }}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 0.9,
              transition: {
                duration: 0.5,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.5,
                delay: 0.25,
              },
            }}
            ref={overlayRef}
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}
