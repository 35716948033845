import React from 'react'
import { StandardPadding, Text, theme } from '../../shared'
import { ITeamMember } from './people'
import { Image, Box, Link } from 'rebass'

const personalInfoTitles = {
  homeTown: 'Hometown',
  inspiration: 'Inspiration',
  book: 'Favorite Book',
  podcast: 'Favorite Podcast',
}
interface IPersonalInfoProps {
  type: keyof typeof personalInfoTitles
  value: string
}

export const NewTeamMemberProfile = ({
  imageSrc,
  imageDesc,
  fullName,
  description,
  socialMedia,
  name,
  body,
  personalInfo,
}: ITeamMember) => (
  <StandardPadding>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Image sx={{ height: '200px', width: 'auto', alignSelf: 'center' }} src={imageSrc} alt={imageDesc} />
      <Text sx={{ marginTop: theme.verticalElementSpacing, color: theme.colors.black, marginBottom: '10px' }} tag="h1">
        {fullName}
      </Text>
      <Text>{description}</Text>
      <Box sx={{ marginTop: '15px' }}>
        <Link paddingX={'1rem'} href={`https://www.linkedin.com/in/${socialMedia.linkedIn}/`}>
          <Image sx={{ width: 'auto', height: 'auto' }} src="/static/images/social-media/LinkedIn.svg" alt="linkedin" />
        </Link>
        <Link paddingX={'1rem'} href={`https://twitter.com/${socialMedia.twitter}`}>
          <Image sx={{ width: 'auto', height: 'auto' }} src="/static/images/social-media/Twitter.svg" alt="twitter" />
        </Link>
      </Box>
    </Box>
    <Box
      sx={{
        paddingTop: theme.halfVerticalSectionSpacing,
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ width: ['100%', '100%', '70%'] }}>
        <Text sx={{ color: theme.colors.blackPrimary, marginBottom: theme.verticalElementSpacing }} tag="h2">
          Meet {name}
        </Text>
        <Text>{body}</Text>
      </Box>
      <Box
        sx={{
          width: ['100%', '100%', '20%'],
          marginTop: [theme.verticalElementSpacing, theme.verticalElementSpacing, 0],
        }}
      >
        <PersonalInfo type="homeTown" value={personalInfo.homeTown} />
        <PersonalInfo type="inspiration" value={personalInfo.inspiration} />
        <PersonalInfo type="book" value={personalInfo.book} />
        <PersonalInfo type="podcast" value={personalInfo.podcast} />
      </Box>
    </Box>
  </StandardPadding>
)

export const PersonalInfo = ({ type, value }: IPersonalInfoProps) => (
  <>
    <Text tag="h3" sx={{ color: theme.colors.blackPrimary }}>
      {personalInfoTitles[type]}
    </Text>
    <Text sx={{ marginBottom: '1rem' }}>{value}</Text>
  </>
)
