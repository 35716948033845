import React from 'react'
import { Image } from 'rebass'
import { motion, AnimateSharedLayout } from 'framer-motion'
import { FlexColumnLayout, FlexRowLayout, Text } from '@northone/ui'

import { IExpansionNavProps } from './desktop'
import { AnimatedExpansionNavLinkContainer } from './link-container'
import { OpenAccountButton, theme } from '../../../shared'
import { WEB_BANKING_URL } from '../../../utils/urls'

const SIDE_PADDING_LEFT = 25
const SIDE_PADDING_RIGHT = 23
// const SIDE_PADDING_TOTAL = SIDE_PADDING_LEFT + SIDE_PADDING_RIGHT
export const ExpansionNavMobile = ({ onCloseClick }: IExpansionNavProps) => {
  return (
    <AnimateSharedLayout>
      <motion.div
        layout
        style={{
          position: 'inherit',
          overflow: 'hidden',
        }}
        initial={{
          backgroundColor: theme.colors.goldTint3,
          width: '100vw',
          zIndex: 10,
          paddingLeft: SIDE_PADDING_LEFT,
          paddingRight: SIDE_PADDING_RIGHT,
          paddingBottom: 12,
          height: 0,
        }}
        animate={{
          width: '100vw',
          height: '100vh',
          transition: {
            duration: 0.5,
          },
        }}
        exit={{
          height: 0,
          opacity: 0,
          transition: {
            duration: 0.5,
            delay: 0.5,
          },
        }}
      >
        <FlexColumnLayout>
          <motion.div layout>
            <FlexRowLayout
              sx={{
                width: '100%',
                height: 40,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 83,
                paddingTop: '80px',
                paddingBottom: '40px',
                paddingX: '8px',
              }}
            >
              <FlexRowLayout
                onClick={() => window.open(WEB_BANKING_URL, '_blank')}
                sx={{ cursor: 'pointer', width: 150, justifyContent: 'flex-start' }}
              >
                <Image alt="login" src="/navbar/login.svg" sx={{ marginTop: '-2px', height: 'auto', width: 'auto' }} />
                <Text tag="body-bold" textColor={theme.colors.ui2} sx={{ paddingLeft: 10 }}>
                  {'Log in'}
                </Text>
              </FlexRowLayout>
              <OpenAccountButton />
              <FlexRowLayout
                onClick={() => onCloseClick()}
                sx={{ cursor: 'pointer', width: 150, justifyContent: 'flex-end' }}
              >
                <Text tag="body-bold" textColor={theme.colors.ui2} sx={{ paddingRight: 10 }}>
                  {'Close'}
                </Text>
                <Image sx={{ height: 'auto', width: 'auto' }} src="/navbar/close.svg" alt="close" />
              </FlexRowLayout>
            </FlexRowLayout>
          </motion.div>
          <AnimatedExpansionNavLinkContainer />
        </FlexColumnLayout>
      </motion.div>
    </AnimateSharedLayout>
  )
}
