import React, { useState } from 'react'
import { theme, TextBullet, handleButtonClick, Text } from '../../../shared'
import { CarouselPagination, CarouselImageWithBanner } from './homepage-carousel'
import { Text as N1Text } from '@northone/ui'
import { HomeMaxWidth } from '../../../shared/components/home-max-width'
import { Box } from 'rebass'
import { CTAButton } from '../../../shared/components/v2/CtaButton/ctaButton'

interface ICoreFeaturesBullet {
  icon: string
  iconSelected: string
  text: string
}

interface ICarouselContent {
  image: string
  imageDesc: string
  icon: string
  text: string
  iconWidth?: number[]
}
const coreFeaturesCarouselContent: ICarouselContent[] = [
  {
    image: 'static/images/homepage/core-features-carousel-1.jpg',
    imageDesc: 'man holding hammer',
    icon: '/static/images/homepage/core-features-carousel-1-icon.png',
    text: `Streamline your finances with full-featured banking that goes to work with you`,
  },
  {
    image: 'static/images/homepage/core-features-carousel-2.jpg',
    imageDesc: 'construction worker looking at phone',
    icon: '/static/images/homepage/core-features-carousel-2-icon.png',
    iconWidth: [72, 72, 72, 55, 100],
    text: `Easily send ACH and wire² transfers from your phone or laptop`,
  },
  {
    image: 'static/images/homepage/core-features-carousel-3.jpg',
    imageDesc: 'man looking at laptop screen',
    icon: '/static/images/homepage/core-features-carousel-3-icon.png',
    iconWidth: [89, 89, 89, 75, 120],
    text: `Move money with the mobile app and our nationwide ATM network³`,
  },
  {
    image: 'static/images/homepage/core-features-carousel-4.jpg',
    imageDesc: 'woman looking at phone screen',
    icon: '/static/images/homepage/core-features-carousel-4-icon.png',
    iconWidth: [89, 89, 89, 75, 120],
    text: `Easily budget and plan for regular business expenses with NorthOne Envelopes⁴`,
  },
  {
    image: 'static/images/homepage/core-features-carousel-5.jpg',
    imageDesc: 'man referencing his phone while planning with woman',
    icon: '/static/images/homepage/core-features-carousel-5-icon.png',
    iconWidth: [72, 72, 72, 75, 120],
    text: `Tap, swipe, and buy online with a dedicated Mastercard® business debit card⁵`,
  },
]
const coreFeaturesList: ICoreFeaturesBullet[] = [
  {
    icon: '/static/images/homepage/core-features-insured-icon.svg',
    iconSelected: '/static/images/homepage/core-features-insured-icon-selected.svg',
    text: 'Digital banking tools',
  },
  {
    icon: '/static/images/homepage/core-features-payments-icon.svg',
    iconSelected: '/static/images/homepage/core-features-payments-icon-selected.svg',
    text: 'Simply powerful payments',
  },
  {
    icon: '/static/images/homepage/core-features-deposits-icon.svg',
    iconSelected: '/static/images/homepage/core-features-deposits-icon-selected.svg',
    text: 'Free deposits² & withdrawals',
  },
  {
    icon: '/static/images/homepage/core-features-budget-icon.svg',
    iconSelected: '/static/images/homepage/core-features-budget-icon-selected.svg',
    text: 'Automatic budgeting',
  },
  {
    icon: '/static/images/homepage/core-features-cards-icon.svg',
    iconSelected: '/static/images/homepage/core-features-cards-icon-selected.svg',
    text: 'Business debit cards³',
  },
]

export const CoreFeatures: React.FC = () => {
  const [selectedSlideIndex, setSelectedSlideIndex] = useState<number>(0)
  const CAROUSEL_INTERVAL_DURATION = 5000
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.copperTint4,
        paddingY: [0, 0, theme.verticalSectionSpacing],
        paddingBottom: [
          theme.halfVerticalSectionSpacing,
          theme.halfVerticalSectionSpacing,
          theme.halfVerticalSectionSpacing,
          theme.verticalSectionSpacing,
          theme.verticalSectionSpacing,
        ],
        paddingRight: [0, 0, 80],
      }}
    >
      <Box sx={{ width: '100%', position: 'relative', height: [277, 277, 0] }}>
        {coreFeaturesCarouselContent.map((item, index) => (
          <CarouselImageWithBanner
            hiddenFor="desktop"
            isSelected={index === selectedSlideIndex}
            key={index}
            image={item.image}
            imageDesc={item.imageDesc}
            icon={item.icon}
            text={item.text}
            iconWidth={item.iconWidth}
          />
        ))}
      </Box>
      <HomeMaxWidth sx={{ position: 'relative' }}>
        <Box sx={{ position: 'relative', height: [0, 0, 446, 564] }}>
          {coreFeaturesCarouselContent.map((item, index) => (
            <CarouselImageWithBanner
              hiddenFor="mobile"
              isSelected={index === selectedSlideIndex}
              key={index}
              image={item.image}
              imageDesc={item.imageDesc}
              icon={item.icon}
              iconWidth={item.iconWidth}
              text={item.text}
            />
          ))}
        </Box>
        <Box
          sx={{
            position: ['unset', 'unset', 'absolute', 'absolue', 'absolute'],
            zIndex: 8,
            right: [0, 0, -130, -130, -180],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Box
            sx={{
              bg: theme.colors.ui7,
              paddingY: [20, 20, 20, 23, 44],
              paddingX: [17, 17, 30, 36, 42],
              width: ['100%', '100%', '70%'],
              display: 'flex',
              flexDirection: 'column',
              alignItems: ['center', 'center', 'flex-start'],
            }}
          >
            <N1Text
              tag="h5"
              sx={{
                textAlign: ['center', 'center', 'left'],
                ...theme.fontstyles.eyeBrowText,
              }}
            >
              Core Features
            </N1Text>
            <Box sx={{ marginTop: [theme.verticalElementSpacing / 2, theme.verticalElementSpacing], marginBottom: 10 }}>
              <N1Text
                tag={'h2'}
                sx={{
                  color: theme.colors.blackPrimary,
                  fontFamily: 'Plantin-MT-Pro-Semi-Bold',
                  textAlign: ['center', 'center', 'left'],
                  marginBottom: ['20px', '20px'],
                  fontSize: ['24px', '36px'],
                  lineHeight: ['28px', '40px'],
                  paddingX: ['0px', '0px'],
                  width: ['100%', '100%', '80%'],
                }}
              >
                Never step into a bank branch again
              </N1Text>
            </Box>
            <Box sx={{ alignSelf: ['center', 'center', 'start'], marginBottom: '22px' }}>
              {coreFeaturesList.map((item, index) => (
                <TextBullet
                  key={index}
                  text={item.text}
                  imageSrc={item.icon}
                  imageSelectedSrc={item.iconSelected}
                  isSelected={index === selectedSlideIndex}
                  setSelected={() => setSelectedSlideIndex(index)}
                />
              ))}
            </Box>
            <CTAButton
              text={'Learn More'}
              type={'copperPrimary'}
              onClick={() => handleButtonClick({ url: '/product' })}
              buttonSx={{
                marginTop: 20,
              }}
            />
            <Text
              tag={'p'}
              sx={{
                marginTop: 2,
                textAlign: ['center', 'center', 'left'],
                width: ['100%', '100%', '61%'],
                ...theme.fontstyles.legalDisclaimers,
                paddingX: [20, 35, 0, 0, 0],
              }}
            >
              {
                'NorthOne is a financial technology company, not a bank. Banking services provided by The Bancorp Bank, N.A., Member FDIC.'
              }
            </Text>
          </Box>
        </Box>
      </HomeMaxWidth>
      <CarouselPagination
        carouselLength={coreFeaturesCarouselContent.length}
        selectedSlideIndex={selectedSlideIndex}
        setSelectedSlideIndex={setSelectedSlideIndex}
        duration={CAROUSEL_INTERVAL_DURATION}
      />
    </Box>
  )
}
