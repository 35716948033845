import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Image } from 'rebass'
import Link from 'next/link'
import { FlexRowLayout, Text, FlexColumnLayout } from '@northone/ui'

import { generatePathName } from '../../../helper'
import { theme } from '../../../shared'
import { IActiveLink } from '../dynamic-navbar/dynamic-navbar'
import { SubPathExpansionNavLink } from './sub-path-link'

interface IExpansionNavLinkProps {
  link: IActiveLink
}

export const ExpansionNavLink = ({ link }: IExpansionNavLinkProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)

  const [isCurrentMainPath, setIsCurrentMainPath] = useState<boolean>()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsCurrentMainPath(generatePathName(link.label) === window.location.pathname.split('/')[1])
    }
  }, [])

  const { label } = link

  return (
    <motion.div layout>
      <motion.div layout>
        <FlexRowLayout
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'default',
          }}
          key={label}
        >
          <Link href={`/${generatePathName(label)}`} as={`/${generatePathName(label)}`}>
            {isCurrentMainPath ? (
              <Text
                tag="body-x-large"
                style={{
                  cursor: 'pointer',
                  color: theme.colors.blackPrimary,
                  fontFamily: theme.fonts.plantinProSemiBold,
                  fontSize: '24px',
                  lineHeight: '28px',
                  letterSpacing: '-0.16px',
                }}
              >{`${label}`}</Text>
            ) : (
              <Text
                tag="body-x-large"
                sx={{
                  cursor: 'pointer',
                  color: isOpen ? theme.colors.blackPrimary : theme.colors.blackTint1,
                  fontFamily: theme.fonts.plantinProSemiBold,
                  fontSize: '24px',
                  lineHeight: '28px',
                  letterSpacing: '-0.16px',
                  ':hover': { color: theme.colors.blackPrimary },
                }}
              >{`${label}`}</Text>
            )}
          </Link>
          <Image
            src={isOpen ? '/navbar/chevron-up.svg' : '/navbar/chevron-down.svg'}
            sx={{
              cursor: 'pointer',
              height: 'auto',
              width: 'auto',
            }}
            alt="toggle"
            onClick={toggleOpen}
          />
        </FlexRowLayout>
      </motion.div>
      {
        <AnimatePresence>
          {isOpen && (
            <motion.div
              layout
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <FlexColumnLayout
                sx={{
                  marginTop: 10,
                }}
              >
                {!!link &&
                  !!link.menu &&
                  link.menu.map((menuItem, idx) => (
                    <SubPathExpansionNavLink menuItem={menuItem} key={idx} link={link} />
                  ))}
              </FlexColumnLayout>
            </motion.div>
          )}
        </AnimatePresence>
      }
    </motion.div>
  )
}
