import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { BaseLayout, NewImage, TextBlock, Button, theme } from '../../shared'
import { Box } from 'rebass'

export const APILanding = (): JSX.Element => {
  const router = useRouter()
  useEffect(() => {
    router.push('/')
  }, [])

  return (
    <BaseLayout
      imageComponent={<NewImage src="/static/images/open-banking/openbanking-hero.png" alt="Openbanking hero image" />}
      bodyComponent={
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: theme.verticalElementSpacing,
              alignItems: ['center', 'center', 'flex-start'],
            }}
          >
            <Button text="Coming Soon" url="" type="disabled" />
          </Box>
          <TextBlock
            title={`__API powered__ open banking platform
            `}
            titleTag="h1"
            paragraph={
              'It is time for APIs to come to the banking world. Take control of your bank data and banking activities with our straight forward SDK and powerful JSON APIs.'
            }
          />
        </Box>
      }
    />
  )
}
