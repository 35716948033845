import React from 'react'
import { theme, Text } from '../../shared'
import { Box, Image } from 'rebass'
import { HomeMaxWidth } from '../../shared/components/home-max-width'

const logos = {
  forbes: {
    srcDesktop: {
      url: 'static/images/homepage/forbes-logo-black.png',
      sx: { maxHeight: 65, marginRight: [0, 0, 30] },
    },
  },
  newYorkTimes: {
    srcDesktop: {
      url: 'static/images/homepage/new-york-times-logo-black.png',
      sx: { maxHeight: 65, display: ['none', 'none', 'flex', 'flex', 'flex'] },
    },
    srcMobile: {
      url: 'static/images/homepage/mobile/new-york-times-logo-black-mobile.svg',
      sx: { display: ['flex', 'flex', 'none', 'none', 'none'] },
      height: '100%',
    },
  },
  fortune: {
    srcDesktop: {
      url: 'static/images/homepage/fortune-logo-black.png',
      sx: { maxHeight: 47, marginLeft: [0, 0, 30] },
    },
  },
}

export const OurMission: React.FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingY: [50, 50, 100, 100, 100],
      }}
    >
      <HomeMaxWidth sx={{ alignItems: 'center' }}>
        <Text
          tag={'h5'}
          sx={{
            ...theme.fontstyles.eyeBrowText,
            marginBottom: 20,
          }}
        >
          AS FEATURED IN
        </Text>
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'column', 'row'],
            width: [null, null, '100%', '100%', '100%'],
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            height: [400, 400, 'auto', 'auto', 'auto'],
            paddingY: 20,
          }}
        >
          <Image src={logos.forbes.srcDesktop.url} sx={logos.forbes.srcDesktop.sx} alt="forbes logo" />
          <Image
            src={logos.newYorkTimes.srcDesktop.url}
            sx={logos.newYorkTimes.srcDesktop.sx}
            alt="newyorktimes logo"
          />
          <Image src={logos.newYorkTimes.srcMobile.url} sx={logos.newYorkTimes.srcMobile.sx} alt="newyorktimes logo" />
          <Image src={logos.fortune.srcDesktop.url} sx={logos.fortune.srcDesktop.sx} alt="fortune logo" />
        </Box>
      </HomeMaxWidth>
    </Box>
  )
}
