import React, { useEffect, useState } from 'react'
import { Box } from 'rebass'
import Link from 'next/link'
import { generatePathName } from '../../../helper'
import { IActiveLink } from './dynamic-navbar'
import { theme, Text } from '../../../shared'

const BODY_LARGE_TEXT_STROKE = `0.5px ${theme.colors.blackPrimary}`

interface ITertiaryNavbarLinkProps {
  link: IActiveLink
  menuItem: string
  idx: number
}

export const TertiaryNavbarLink = ({ link, menuItem, idx }: ITertiaryNavbarLinkProps) => {
  const [isCurrentSubPath, setCurrentSubPath] = useState<boolean>()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentSubPath(generatePathName(menuItem) === window.location.pathname.split('/')[2])
    }
  }, [])

  const [hoverTextStroke, setHoverTextStroke] = useState<string>()
  const isBlogMenuItem = menuItem === 'Blog'
  return (
    <Box
      sx={{ marginLeft: idx === 0 ? 0 : 30, cursor: 'pointer', display: 'inline-block' }}
      key={menuItem}
      onMouseEnter={() => setHoverTextStroke(BODY_LARGE_TEXT_STROKE)}
      onMouseLeave={() => setHoverTextStroke(undefined)}
    >
      <Link
        href={isBlogMenuItem ? '/blog' : `/${generatePathName(link.label)}/[menu]`}
        as={isBlogMenuItem ? '/blog' : `/${generatePathName(link.label)}/${generatePathName(menuItem)}`}
      >
        {isCurrentSubPath ? (
          <a style={{ textDecoration: 'none' }} target={isBlogMenuItem ? '_blank' : undefined}>
            <Text
              tag="p"
              sx={{
                cursor: 'pointer',
                WebkitTextStroke: BODY_LARGE_TEXT_STROKE,
                color: theme.colors.blackPrimary,
                fontFamily: `${theme.fonts.grtskPetaSemiBold} !important`,
              }}
            >
              {menuItem}
            </Text>
          </a>
        ) : (
          <a style={{ textDecoration: 'none' }} target={isBlogMenuItem ? '_blank' : undefined}>
            <Text
              tag="p"
              sx={{
                cursor: 'pointer',
                WebkitTextStroke: hoverTextStroke,
                color: theme.colors.blackPrimary,
                ...theme.fontstyles.menu.tertiary,
              }}
            >
              {menuItem}
            </Text>
          </a>
        )}
      </Link>
    </Box>
  )
}
