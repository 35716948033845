import React, { useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { FlexRowLayout, theme as N1Theme } from '@northone/ui'

import { useClickOutside, useDocumentScrollThrottled } from '../utils/hooks'
import { NAVBAR_DIMENSIONS, NAVBAR_LINKS } from '../navbar'
import { SecondaryNavbarLink } from './secondary-link'
import { TertiaryNavbarLink } from './tertiary-link'
import { theme } from '../../../shared'

export interface IActiveLink {
  label: string
  menu?: string[]
}

interface ITertiaryNavBarProps {
  menuItems?: IActiveLink
}

const ANIMATION_VARIANTS = {
  open: { height: 'auto', opacity: 1 },
  collapsed: { height: 0, opacity: 0 },
}

export const DynamicNavBar: React.FC = () => {
  const [isShowingSecondaryNavBar, setIsShowingSecondaryNavBar] = useState(true)
  const [activeMenu, setActiveMenu] = useState<IActiveLink | null>(null)

  const MINIMUM_SCROLL = 0
  const TIMEOUT_DELAY = 200

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData
    const isScrolledDown = previousScrollTop > currentScrollTop
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

    if (currentScrollTop === 0) {
      setIsShowingSecondaryNavBar(true)
    } else {
      setTimeout(() => {
        setActiveMenu(null)
        setIsShowingSecondaryNavBar(isScrolledDown && isMinimumScrolled)
      }, TIMEOUT_DELAY)
    }
  })

  const secondaryNavBarRef = useRef<HTMLDivElement>(null)
  const tertiaryNavBarRef = useRef<HTMLDivElement>(null)

  useClickOutside([tertiaryNavBarRef], () => setActiveMenu(null))

  return (
    <AnimatePresence>
      {isShowingSecondaryNavBar && (
        <motion.div
          layout="position"
          initial="open"
          animate="open"
          exit="collapsed"
          variants={ANIMATION_VARIANTS}
          ref={secondaryNavBarRef}
        >
          <FlexRowLayout
            sx={{
              backgroundColor: N1Theme.colors.ui7,
              height: [NAVBAR_DIMENSIONS.secondary.heightMobile, NAVBAR_DIMENSIONS.secondary.height],
              marginTop: '-1px',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: ['scroll', 'hidden'],
              paddingX: [NAVBAR_DIMENSIONS.secondary.horizontalPaddingMobile, undefined],
              whiteSpace: ['nowrap', undefined],
            }}
          >
            {NAVBAR_LINKS.map((link, idx) => (
              <SecondaryNavbarLink link={link} idx={idx} key={`${link}-${idx}`} setActiveMenu={setActiveMenu} />
            ))}
          </FlexRowLayout>
        </motion.div>
      )}
      {activeMenu && activeMenu.menu && (
        <motion.div
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={ANIMATION_VARIANTS}
          ref={tertiaryNavBarRef}
        >
          {<TertiaryNavBar menuItems={activeMenu} />}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const TertiaryNavBar: React.FC<ITertiaryNavBarProps> = ({ menuItems }: ITertiaryNavBarProps) => (
  <FlexRowLayout
    sx={{
      height: NAVBAR_DIMENSIONS.tertiary.height,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.goldTint3,
      // marginTop: '12px',
      marginRight: '4px',
    }}
  >
    {menuItems &&
      menuItems.menu &&
      menuItems.menu.map((menuItem, idx) => (
        <TertiaryNavbarLink key={idx} link={menuItems} menuItem={menuItem} idx={idx} />
      ))}
  </FlexRowLayout>
)
