import React, { useState } from 'react'
import { Box, Image, Link as RLink } from 'rebass'
import { FlexRowLayout, theme as N1Theme } from '@northone/ui'

import { MobileLearnMoreButton, NavBarButton } from './navbar-button'
import { NAVBAR_DIMENSIONS } from './navbar'
import { theme } from '../../shared'
import { AnimatedExpansionNavContainer } from './expansion-nav/expansion-nav'
import { OpenAccountButton } from '../../shared'
import { WEB_BANKING_URL } from '../../utils/urls'

interface IStaticNavbarProps {
  shouldShowOverlay: boolean
  setShouldShowOverlay: (shouldShowOverlay: boolean) => void
}

export const StaticNavbar: React.FC = () => {
  const [shouldShowOverlay, setShouldShowOverlay] = useState(false)

  return (
    <React.Fragment>
      <AnimatedExpansionNavContainer
        shouldShowOverlay={shouldShowOverlay}
        setShouldShowOverlay={setShouldShowOverlay}
      />
      <Box
        sx={{
          display: ['none', 'none', 'flex'],
        }}
      >
        <DesktopStaticNavbar shouldShowOverlay={shouldShowOverlay} setShouldShowOverlay={setShouldShowOverlay} />
      </Box>
      <Box
        sx={{
          display: ['flex', 'flex', 'none'],
        }}
      >
        <MobileStaticNavBar shouldShowOverlay={shouldShowOverlay} setShouldShowOverlay={setShouldShowOverlay} />
      </Box>
    </React.Fragment>
  )
}

const DesktopStaticNavbar = ({ shouldShowOverlay, setShouldShowOverlay }: IStaticNavbarProps) => {
  return (
    <FlexRowLayout
      sx={{
        height: theme.navbar.height,
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingTop: '7px',
        paddingLeft: '67px',
        paddingRight: NAVBAR_DIMENSIONS.primary.horizontalPaddingRightDesktop,
        backgroundColor: N1Theme.colors.ui7,
      }}
    >
      <NavBarButton
        text={'Learn More'}
        marginRight={10}
        customWidth={160}
        customHeight={42}
        hoverStateColor={theme.colors.hoverStateLearMoreHeader}
        iconSrc={'/navbar/hamburger.svg'}
        onClick={() => setShouldShowOverlay(!shouldShowOverlay)}
      />

      <Box
        sx={{
          width: theme.navbar.logoWidth,
        }}
      >
        <RLink href="/" sx={{ cursor: 'pointer', marginLeft: ['0px', '0px', '0px', '94px'] }}>
          <Image src="/navbar/logo.svg" alt="northone" />
        </RLink>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '67px' }}>
        <NavBarButton
          text={'Log in'}
          marginRight={10}
          customWidth={131}
          customHeight={42}
          customBackgroundColor={theme.colors.goldTint4}
          hoverStateColor={theme.colors.hoverStateLogin}
          iconSrc={'/navbar/login.svg'}
          onClick={() => window.open(WEB_BANKING_URL, '_blank')}
        />
        <OpenAccountButton
          buttonSx={{
            width: '230px',
            height: 42,
          }}
        >
          <NavBarButton
            text={'Learn More'}
            iconSrc={'/navbar/hamburger.svg'}
            onClick={() => setShouldShowOverlay(!shouldShowOverlay)}
          />
        </OpenAccountButton>
      </Box>
    </FlexRowLayout>
  )
}

const MobileStaticNavBar = ({ shouldShowOverlay, setShouldShowOverlay }: IStaticNavbarProps) => {
  return (
    <FlexRowLayout
      sx={{
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 40px',
      }}
    >
      <RLink href="/" sx={{ cursor: 'pointer' }}>
        <Box
          sx={{
            height: 20,
          }}
        >
          <Image sx={{ height: 'auto', width: 'auto' }} src="/navbar/logo.svg" alt="northone" style={{ height: 20 }} />
        </Box>
      </RLink>
      <MobileLearnMoreButton onClick={() => setShouldShowOverlay(!shouldShowOverlay)} />
    </FlexRowLayout>
  )
}
