import React from 'react'
import { ExpansionPanel, StandardPadding, TextHero, theme } from '../../shared'
import { Box, Text } from 'rebass'
import { Button, ButtonTypeEnum } from '@northone/ui'

interface IFAQProps {
  titleTag?: 'h1' | 'h2' | 'h3'
  header: string
  subHeader?: string
  content: {
    title: string
    index?: number
    text: JSX.Element
  }[]
  buttonText?: string
  href?: string
  centered?: boolean
  leftAlignText?: boolean
  hasBlueBackground?: boolean
  hasBrownBackground?: boolean
  customHorizontalPadding?: string[] | string | number[] | number
  customVerticalPadding?: string[] | string
  customTopPadding?: string[] | string
  textHeroPaddingBottom?: string[] | string
  marginTop?: string | number | (string | number)[]
  titleHighlightColor?: string
}
export const FAQ = ({
  titleTag = 'h2',
  header,
  subHeader,
  content,
  buttonText,
  href,
  centered,
  hasBlueBackground,
  hasBrownBackground,
  customHorizontalPadding,
  customVerticalPadding,
  customTopPadding,
  leftAlignText,
  textHeroPaddingBottom,
  marginTop,
}: // titleHighlightColor,
IFAQProps): JSX.Element => {
  return (
    <StandardPadding
      marginTop={marginTop || [50, 50, 100, 100]}
      sx={{
        alignItems: 'center',
        backgroundColor: hasBlueBackground
          ? theme.colors.blue
          : hasBrownBackground
          ? theme.colors.gold100
          : theme.colors.white,
        paddingY: customVerticalPadding || theme.verticalSectionSpacing,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: theme.contentMaximumWidth,
          color: theme.colors.white,
          backgroundColor: hasBlueBackground
            ? theme.colors.blue
            : hasBrownBackground
            ? theme.colors.gold100
            : theme.colors.white,
          alignItems: leftAlignText ? 'flex-start' : 'center',
        }}
      >
        <TextHero
          textColor={hasBlueBackground || hasBrownBackground ? theme.colors.white : theme.colors.blackPrimary}
          titleTag={titleTag}
          title={header}
          // titleHighlightColor={titleHighlightColor}
          subContent={
            <Text sx={{ color: hasBlueBackground || hasBrownBackground ? theme.colors.white : theme.colors.blue }}>
              {subHeader}
            </Text>
          }
          centered={centered}
          customHorizontalPadding={customHorizontalPadding}
          customTopPadding={customTopPadding}
          hasBlueBackground={hasBlueBackground}
          hasBrownBackground={hasBrownBackground}
          leftAlignText={leftAlignText}
          textHeroPaddingBottom={textHeroPaddingBottom}
        />
        <Box style={{ width: '100%', marginBottom: 30 }}>
          {content.map((component, index) => (
            <ExpansionPanel
              key={index}
              title={component.title}
              text={component.text}
              hasBlueBackground={hasBlueBackground}
              hasBrownBackground={hasBrownBackground}
            />
          ))}
        </Box>
        {buttonText ? (
          <Button onClick={() => window.open(href)} type={ButtonTypeEnum.PRIMARY_GOLD_INVERTED}>
            {buttonText}
          </Button>
        ) : (
          ''
        )}
      </Box>
    </StandardPadding>
  )
}
