export const TESTIMONIALS_CONTENT = [
  {
    imgPath: '/static/images/testimonials/isaac.png',
    imageDesc: 'isaac',
    title: 'Just what I was looking for',
    text: 'I don’t have to run to a bank to deposit a check. This is life-changing.',
    name: 'Isaac',
    occupation: 'Chef | Tulsa, OK',
  },
  {
    imgPath: '/static/images/testimonials/liam.png',
    imageDesc: 'liam',
    title: 'Great service',
    text:
      'Owning a gym means endless hours on my feet and the last thing I want to do is spend time standing in line at the bank.',
    name: 'Liam',
    occupation: 'Gym Owner | Memphis, TN',
  },
  {
    imgPath: '/static/images/testimonials/david.png',
    imageDesc: 'david',
    title: 'Needed this',
    text:
      'Owning two businesses means that my entire life revolves around my work. Banking used to be the most time-consuming part of it all, but since I got my free time back.',
    name: 'David',
    occupation: 'Dentist | Sunrise, FL',
  },
  {
    imgPath: '/static/images/testimonials/shannon.png',
    imageDesc: 'shannon',
    title: `Couldn't be better`,
    text: 'I can chat with customer support, or SCHEDULE a call with them. Don’t ever change that!',
    name: 'Shannon',
    occupation: 'Freelance Photographer | Atlanta, GA',
  },
]
