import React from 'react'
import { HomeMaxWidth } from '../../shared/components/home-max-width'
import { theme } from '../../shared'
import { Box, SxStyleProp, Image } from 'rebass'
import { FlexRowLayout, Text, ScrollIndicator } from '@northone/ui'
import { TextWithHighlight } from '../../shared/components/text-with-highlight'

interface ISupportProps {
  withoutScrollIndicator?: boolean
  customBody?: string
  superScript?: string
}

interface ISupportBoxProps {
  title: string
  subtitle: string
  subtitleSx?: SxStyleProp
  color: string
  imgSrc: string
  sx?: SxStyleProp
  onClick: () => void
}

export const Support = ({ withoutScrollIndicator, customBody, superScript }: ISupportProps) => (
  <FlexRowLayout
    sx={{
      paddingBottom: [
        theme.halfVerticalSectionSpacing,
        theme.halfVerticalSectionSpacing,
        theme.verticalSectionSpacing,
        theme.verticalSectionSpacing,
        theme.verticalSectionSpacing,
      ],
      paddingTop: [30, 20, theme.verticalSectionSpacing, theme.verticalSectionSpacing, theme.verticalSectionSpacing],
      justifyContent: 'center',
      position: 'relative',
    }}
  >
    <HomeMaxWidth
      sx={{
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: ['100%', '100%', '50%', '40%', '40%'],
          paddingRight: [0, 0, 100, 45, 50],
          marginBottom: [theme.verticalElementSpacing, theme.verticalElementSpacing, 0],
        }}
      >
        <Text
          textColor={theme.colors.copperPrimary}
          sx={{
            ...theme.fontstyles.eyeBrowText,
            marginBottom: theme.verticalElementSpacing,
            textAlign: ['center', 'center', 'left'],
          }}
          tag="h5"
        >
          Customer Care
        </Text>
        <TextWithHighlight
          centered={[true, true, false]}
          tag={['h3Serif', 'h3Serif', 'h3Serif', 'h2']}
          // highlightColor="#EAE7E1"
          highlightColor={'transparent'}
          textColor={theme.colors.blackPrimary}
          superScript={superScript || '7'}
        >
          {'Instant __support__ from real human beings'}
        </TextWithHighlight>
        <Text
          sx={{
            marginTop: 20,
            fontFamily: theme.fonts.grtskPetaLight,
            color: theme.colors.blackPrimary,
            lineHeight: '22px',
            letterSpacing: '-0.16px',
            textAlign: ['center', 'center', 'left'],
          }}
          tag={['body', 'body', 'body', 'body-large']}
        >
          {customBody ||
            'Talk to a local customer support expert who understands your business.\n\n Want to see how quickly we’ll answer?\n Click the chat box in the corner and say hello.'}
        </Text>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column', 'row', 'row', 'row'],
          flexGrow: 1,
          justifyContent: 'flex-end',
          width: ['100%', '100%', 'auto'],
          alignItems: 'center',
        }}
      >
        <SupportBox
          imgSrc={'/static/images/homepage/can-telephone-black-&-white.png'}
          title="Live chat"
          subtitle={'Talk to the team through our app or website'}
          color={theme.colors.copperTint3}
          onClick={() => window.Intercom('show')}
        />
        <SupportBox
          imgSrc={'/static/images/homepage/phone-black-&-white.png'}
          title="Phone"
          subtitle={"Never wait on hold again. We'll call you."}
          sx={{ marginX: [0, 0, 40, 40, 40], marginY: [15, 15, 0, 0, 0] }}
          color={theme.colors.copperTint3}
          onClick={() => window.open('tel:+18332221802', '_self')}
        />
        <SupportBox
          imgSrc={'/static/images/homepage/mailbox-black-&-white.png'}
          title="Email"
          subtitle={'Expect a fast, human response.'}
          subtitleSx={{ width: ['80%', '80%', '90%', '90%', '90%'] }}
          color={theme.colors.copperTint3}
          onClick={() => window.open('mailto:support@northone.com', '_self')}
        />
      </Box>
    </HomeMaxWidth>
    {withoutScrollIndicator ? <ScrollIndicator startShowingIndicatorAtVHPercentage={80} /> : <></>}
  </FlexRowLayout>
)

export const SupportBox = ({ title, color, sx, subtitle, imgSrc, onClick, subtitleSx }: ISupportBoxProps) => {
  return (
    <Box
      sx={{
        backgroundColor: color,
        ':hover': title === 'Phone' ? null : { backgroundColor: theme.colors.copperTint2 },
        height: ['124px', '124px', '286px', '286px', '286px'],
        width: ['332px', '332px', '210px', '191px', '191px'],
        display: 'flex',
        flexDirection: ['row', 'row', 'column', 'column', 'column'],
        justifyContent: 'space-between',
        position: 'relative',
        maxWidth: 400,
        cursor: title === 'Phone' ? null : 'pointer',
        ...sx,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          paddingY: '15px',
          display: 'flex',
          flexDirection: ['column'],
          justifyContent: 'space-evenly',
          width: ['60%', '60%', '100%', '100%', '100%'],
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Text
            sx={{
              fontFamily: theme.fonts.plantinProSemiBold,
              fontSize: '20px',
              lineHeight: '24px',
              letterSpacing: '-0.16px',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '20px',
              color: theme.colors.blackPrimary,
            }}
            tag="body-bold"
          >
            {title}
          </Text>
          {title === 'Phone' ? null : (
            <Image
              sx={{
                display: ['none', 'none', 'block', 'block', 'block'],
                height: '12px',
                marginBottom: ['0px', '0px', '5px', '5px', '5px'],
                paddingRight: '20px',
              }}
              src={`/Support-Arrow.png`}
            />
          )}
        </Box>

        <Text
          tag="body-small"
          sx={{
            width: '95%',
            ...subtitleSx,
            marginBottom: '2px',
            display: ['block', 'block', 'none', 'none', 'none'],
            paddingLeft: '20px',
            fontFamily: theme.fonts.grtskPetaLight,
            color: theme.colors.blackPrimary,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '-0.16px',
            marginY: ['5px', '5px', '0px', '0px', '0px'],
            height: 'auto',
          }}
        >
          {subtitle}
        </Text>
        <Image
          sx={{
            width: '50px',
            paddingLeft: '20px',
            display: ['block', 'block', 'none', 'none', 'none'],
            marginTop: '5px',
            height: 'auto',
          }}
          src={`/Support-Arrow.png`}
        />

        <Image
          sx={{
            display: ['none', 'none', 'block', 'block', 'block'],
            height: ['0px', '0px', '80px', '159px', '159px'],
          }}
          src={imgSrc}
        />
        <Image
          sx={{
            position: 'absolute',
            display: ['block', 'block', 'none', 'none', 'none'],
            height: '124px',
            right: '0px',
          }}
          src={`${imgSrc.substring(0, imgSrc.length - 4)}-mobile.png`}
        />
        <Box sx={{ display: 'flex', alignItems: 'flex-end', height: [null, null, null, null, '67px'] }}>
          <Text
            tag="body-small"
            sx={{
              width: ['0%', '0%', '95%', '95%', '80%'],
              marginBottom: '2px',
              display: ['none', 'none', 'block', 'block', 'block'],
              fontFamily: theme.fonts.grtskPetaLight,
              color: theme.colors.blackPrimary,
              fontSize: '14px',
              lineHeight: '20px',
              letterSpacing: '-0.16px',
              marginTop: ['0px', '0px', '30px', '0px', '0px'],
              paddingLeft: '20px',
              paddingRight: '20px',
              ...subtitleSx,
            }}
          >
            {subtitle}
          </Text>
        </Box>
      </Box>
      <Image
        sx={{
          position: 'absolute',
          display: ['none', 'none', 'none', 'none', 'block'],
          height: '173px',
          width: 'auto',
        }}
        src={`https://northone-assets.s3.us-east-2.amazonaws.com/website/homepage/${imgSrc}-Desktop.png`}
      />
      <Image
        sx={{
          position: 'absolute',
          display: ['none', 'none', 'block', 'block', 'none'],
          height: '100px',
          right: '0px',
          width: 'auto',
        }}
        src={`https://northone-assets.s3.us-east-2.amazonaws.com/website/homepage/${imgSrc}-Tablet.png`}
      />
      <Image
        sx={{
          position: 'absolute',
          display: ['block', 'block', 'none', 'none', 'none'],
          height: '124px',
          right: '0px',
          width: 'auto',
        }}
        src={`https://northone-assets.s3.us-east-2.amazonaws.com/website/homepage/${imgSrc}-Mobile.png`}
      />
      <Text
        sx={{
          paddingX: ['0px', '0px', '0px', '0px', '15px'],
          paddingBottom: [10, 10, 10, 10, 15],
          display: ['none', 'none', 'none', 'none', 'block'],
          height: 'auto',
          width: 'auto',
        }}
        tag="body"
      >
        {subtitle.replace(/\n/g, ' ')}
      </Text>
    </Box>
  )
}
