export const STARTUPLANDING_CONTENT = {
  vectorDesktop: '/static/images/our-customers/startups/startups-vector.svg',
  vectorMobile: '/static/images/our-customers/startups/startups-vector-mobile.svg',
  contentBlockTitle: 'Banking for Startups',
  contentBlockText:
    'NorthOne banking is built for startups. We listened to real founders to create a bank that helps you achieve your goals. Get simple but powerful banking and finance tools that help you manage your runway and burn rate. It takes as little as 3 minutes to open a NorthOne FDIC-insured business account that’s 100% digital.',
  contentBlockImg: '/static/images/our-customers/startups/startups-hero.png',
  contentImgDesc: 'iPhone showing notification of revenue chart',
  h3Text:
    'Take care of business with the smartest bank account your business can get. \nNo branches. No paperwork. No headaches.',
}

export const FASTER_SMARTER_BANKING_LIST = [
  { text: 'Works with billing systems like Stripe' },
  { text: 'Supports all modern payments types' },
  { text: 'Pay invoices' },
  { text: 'Simple and powerful insights into your cashflow' },
]

export const STARTUP_INTEGRATIONS_ICON_LIST = [
  {
    icon: '/static/images/logo/expensify_logo.svg',
    title: 'Expensify',
    alt: 'expensify logo',
    link: '/product/integrations/expensify',
  },
  {
    icon: '/static/images/logo/freshbooks_logo.svg',
    title: 'Freshbooks',
    alt: 'freshbooks logo',
    link: '/product/integrations/freshbooks',
  },
  {
    icon: '/static/images/logo/quickbooks_logo.svg',
    title: 'Quickbooks',
    alt: 'quickbooks logo',
    link: '/product/integrations/quickbooks',
  },
  {
    icon: '/static/images/logo/shopify_logo.svg',
    title: 'Shopify',
    alt: 'shopify logo',
    link: '/product/integrations/shopify',
  },
  {
    icon: '/static/images/logo/wave_logo.svg',
    title: 'Wave',
    alt: 'wave logo',
    link: '/product/integrations/wave',
  },
  // {
  //   icon: '/static/images/logo/xero_logo.svg',
  //   title: 'Xero',
  //   alt: 'xero logo',
  //   link: '/product/integrations/xero',
  // },
  {
    icon: '/static/images/logo/andmore_logo.svg',
    title: 'and more',
    alt: 'and more',
    link: '/product/integrations',
  },
]
