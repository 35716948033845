import React from 'react'
import { theme, TextBlock } from '../../shared'
import { fireWebsiteSignupEvent } from '../../utils'
import { Text, FlexColumnLayout } from '@northone/ui'

import { Box, Image, Link } from 'rebass'
import { HomeMaxWidth } from '../../shared/components/home-max-width'
import { isExternalURL } from '../../helper'
import { ONBOARDING_URL } from '../../utils/urls'
import { CTAButton } from '../../shared/components/v2/CtaButton/ctaButton'

export const TrustedBy: React.FC = () => (
  <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.colors.goldTint2,
        width: '100%',
        paddingTop: ['60px', '60px', '60px', theme.halfVerticalSectionSpacing, theme.halfVerticalSectionSpacing],
      }}
    >
      <HomeMaxWidth
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column', 'row', 'row'],
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'colummn',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <TextBlock
            containerSx={{
              width: ['100%', '100%', '90%', '70%', '70%'],
            }}
            title={`Trusted by 320,000+ American businesses`}
            titleTag={'h2'}
            titleContainerSx={{}}
            titleStyle={{
              fontFamily: theme.fonts.plantinProSemiBold,
              fontSize: ['24px', '24px', '36px', '36px', '36px'],
              lineHeight: ['28px', '28px', '40px', '40px', '40px'],
              letterSpacing: '-0.01em',
              color: theme.colors.blackPrimary,
            }}
            paragraphTag="body-large"
            paragraphColor={theme.colors.blackPrimary}
            paragraphJSX={
              <>
                <Text
                  tag={'body-large'}
                  sx={{
                    textAlign: ['center', 'center', 'left', 'left', 'left'],
                    color: theme.colors.blackPrimary,
                    fontFamily: theme.fonts.grtskPetaLight,
                    fontSize: '16px',
                    lineHeight: '22px',
                    letterSpacing: '-0.16px',
                    marginBottom: '30px',
                  }}
                >
                  NorthOne makes banking simple for small businesses. By talking to real business owners, we built a
                  banking experience that takes the stress out of managing your <br /> day to day finances.
                </Text>
              </>
            }
            subContent={
              <FlexColumnLayout
                sx={{
                  alignItems: ['center', 'center', 'flex-start', 'flex-start', 'flex-start'],
                  textAlign: ['center', 'center', 'left', 'left', 'left'],
                  width: ['100%', '100%', '65%', '65%', '65%'],
                  marginBottom: ['40px', '40px', '0px', '0px', '0px'],
                }}
              >
                <Link
                  href={ONBOARDING_URL}
                  onClick={fireWebsiteSignupEvent}
                  target={isExternalURL(ONBOARDING_URL) ? '_blank' : ''}
                  sx={{
                    width: '100%',
                  }}
                >
                  <CTAButton
                    text={'Apply for an Account'}
                    type={'blackPrimary'}
                    buttonSx={{ marginBottom: '5px', cursor: 'pointer', width: '100%' }}
                  />
                </Link>
                <Text
                  tag={'body-x-small'}
                  sx={{
                    paddingTop: '7px',
                    paddingBottom: '5px',
                    textAlign: ['center', 'center', 'left', 'left', 'left'],
                    width: ['80%', '75%', '95%', '95%', '95%'],
                    ...theme.fontstyles.legalDisclaimers,
                  }}
                >
                  {
                    'NorthOne is a financial technology company, not a bank. Banking services provided by The Bancorp Bank, N.A., Member FDIC.'
                  }
                </Text>
              </FlexColumnLayout>
            }
          />
        </Box>

        <Image
          sx={{ display: ['none', 'none', 'block', 'block', 'block'], width: [0, 0, 1000, 1200, 1200] }}
          src="static/images/homepage/trusted-by-section.webp"
          alt="trusted by"
        />
        <Image
          sx={{ display: ['block', 'block', 'none', 'none', 'none'], width: 300 }}
          src="static/images/homepage/mobile/trusted-by-section-mobile.webp"
          alt="trusted by in mobile view"
        />
      </HomeMaxWidth>
    </Box>
  </>
)
