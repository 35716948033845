import { useEffect, useState } from 'react'
import { throttle } from 'lodash'

export const useDocumentScrollThrottled = (callback: (o: Record<string, number>) => void) => {
  const [, setScrollPosition] = useState(0)
  let previousScrollTop = 0

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } = document.documentElement || document.body

    setScrollPosition(previousPosition => {
      previousScrollTop = previousPosition
      return currentScrollTop
    })

    callback({ previousScrollTop, currentScrollTop })
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250)

  useEffect(() => {
    window.addEventListener('scroll', handleDocumentScrollThrottled)

    return () => window.removeEventListener('scroll', handleDocumentScrollThrottled)
  }, [])
}

export const useClickOutside = (refs: React.RefObject<HTMLDivElement>[], cb: () => void): void => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      refs.map(ref => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          cb()
        }
      })
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [refs])
}

export const useClickInside = (refs: React.RefObject<HTMLDivElement>[], cb: () => void): void => {
  useEffect(() => {
    function handleClickInside(event: MouseEvent) {
      refs.map(ref => {
        if (ref.current && ref.current.contains(event.target as Node)) {
          cb()
        }
      })
    }

    document.addEventListener('mousedown', handleClickInside)
    return () => {
      document.removeEventListener('mousedown', handleClickInside)
    }
  }, [refs])
}
