import React from 'react'
import { theme, Text } from '../../shared'
import { isExternalURL } from '../../helper'
import { Box, Image, Link } from 'rebass'
import { FlexRowLayout } from '@northone/ui'
import { footerItems, socialLinks } from './data'

type IFooterColumnProps = {
  header: string
  links: Array<{
    text: string
    href: string
    isBlank?: boolean
  }>
  hiddenFor?: 'mobile' | 'laptop'
  width?: Array<string | number>
}
type IFooterProps = {
  customBody?: string | JSX.Element
}

const FOOTER_WIDTHS = ['100%', '100%', 200]

const FooterColumn = ({ header, links, hiddenFor, width }: IFooterColumnProps): JSX.Element => {
  const getDisplay = () => {
    switch (hiddenFor) {
      case 'mobile':
        return ['none', 'none', 'flex']
      case 'laptop':
        return ['flex', 'flex', 'none']
      default:
        return 'flex'
    }
  }
  return (
    <Box
      sx={{
        width: width || FOOTER_WIDTHS,
        marginBottom: 25,
        display: getDisplay(),
        flexDirection: 'column',
        paddingRight: 20,
      }}
    >
      <Text
        tag={'p'}
        sx={{
          ...theme.fontstyles.eyeBrowText,
          marginBottom: [16, 16, 16, 24],
        }}
      >
        {header.toUpperCase()}
      </Text>
      {links.map((link, idx) => (
        <Box key={idx} sx={{ marginBottom: '8px' }}>
          <Link target={isExternalURL(link.href) || link.isBlank ? '_blank' : undefined} href={link.href}>
            <Text
              tag={'p'}
              sx={{
                color: theme.colors.blackPrimary,
                marginBottom: ['8px', '8px', '8px', 12],
                fontFamily: theme.fonts.grtskPetaLight,
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '-0.16px',
              }}
            >
              {link.text}
            </Text>
          </Link>
        </Box>
      ))}
    </Box>
  )
}

const FooterContent = ({ customBody }: IFooterProps): JSX.Element => {
  const coreContent = `© 2022 NorthOne. All Rights Reserved.

  All trademarks and brand names belong to their respective owners. Use of these trademarks and brand names do not represent endorsement by or association with this card program.
  
  Banking Services provided by The Bancorp Bank, N.A., Member FDIC. The NorthOne Mastercard® Small Business Debit Card is issued by The Bancorp Bank, N.A. pursuant to license by Mastercard International Incorporated and may be used everywhere Debit Mastercard is accepted. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated. The Bancorp Bank, N.A. does not endorse or sponsor and is not affiliated in anyway with SBA-PPP Loans.
  
  IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an Account. What this means for you: When you open an Account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver’s license or other identifying documents.`

  return (
    <>
      {customBody && (
        <Text
          as="div"
          tag={'p'}
          sx={{
            color: theme.colors.blackPrimary,
            fontFamily: theme.fonts.grtskExa,
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: 400,
            letterSpacing: '-0.16px',
            fontStyle: 'normal',
            width: ['auto', 'auto', 'auto', '80%'],
          }}
        >
          {customBody}
        </Text>
      )}
      <Text
        tag={'p'}
        sx={{
          color: theme.colors.blackPrimary,
          fontFamily: theme.fonts.grtskExa,
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: 400,
          letterSpacing: '-0.16px',
          fontStyle: 'normal',
          width: ['auto', 'auto', 'auto', '80%'],
        }}
      >
        {coreContent}
      </Text>
    </>
  )
}

export const Footer: React.FC<IFooterProps> = ({ customBody }: IFooterProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      paddingY: [40, 40, 40, 90],
      paddingX: [0, 0, 75],
      flexDirection: 'column',
    }}
  >
    <FlexRowLayout
      sx={{
        flexDirection: 'row',
        maxWidth: 1290,
        width: ['85%', '80%', '100%'],
        justifyContent: 'space-between',
        flexWrap: ['wrap', 'wrap', 'nowrap'],
        borderBottom: `1px solid ${theme.colors.ui4}`,
        paddingBottom: [10, 30, 30, 60],
      }}
    >
      <Box sx={{ width: [0, 0, 100, 100, 180] }}>
        <Image sx={{ width: 60, height: 60 }} src="/static/images/footer/northone-logo.svg" alt="northone logo" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['row', 'row', 'column', 'row'],
          width: ['100%', '100%', 'auto'],
        }}
      >
        <FooterColumn width={['50%', '50%', 200]} {...footerItems.aboutUs} />
        <FooterColumn width={['50%', '50%', 200]} {...footerItems.resources} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: ['row', 'row', 'column', 'row'], width: ['100%', '100%', 'auto'] }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: ['100%', '100%', 'auto'] }}>
          <FooterColumn width={['100%', '100%', '285px']} {...footerItems.security} />
          <FooterColumn hiddenFor="laptop" {...footerItems.offices} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: ['100%', '100%', 'auto'] }}>
          <FooterColumn {...footerItems.connect} />
          <FooterColumn {...footerItems.help} />
        </Box>
      </Box>
      <FooterColumn width={['100%', '100%', 220]} hiddenFor="mobile" {...footerItems.offices} />
    </FlexRowLayout>
    <Box
      sx={{
        paddingY: 24,
        width: ['85%', '80%', '100%'],
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1290,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column', 'row'],
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Text
          tag={'h3'}
          sx={{
            color: theme.colors.blackPrimary,
            fontFamily: theme.fonts.plantinProSemiBold,
            fontSize: '24px',
            lineHeight: '28px',
            letterSpacing: '-0.16px',
            paddingBottom: '24px',
          }}
        >
          {'Business banking\nmade simple'}
        </Text>
        <Box sx={{ marginTop: [12, 12, 0, 0, 0], marginBottom: [14, 14, 0, 0, 0] }}>
          {socialLinks.map(({ img, href, alt }, index) => (
            <Link key={index} sx={{ marginLeft: index === 0 ? 0 : '6px' }} href={href} target="_blank">
              <Image sx={{ width: 'auto', height: 'auto' }} src={img} alt={alt} />
            </Link>
          ))}
        </Box>
      </Box>
      <FooterContent customBody={customBody} />
    </Box>
  </Box>
)
