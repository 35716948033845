import React from 'react'
import { Box, Image } from 'rebass'
import { motion, AnimateSharedLayout } from 'framer-motion'
import { FlexColumnLayout, FlexRowLayout, Text } from '@northone/ui'

import { AnimatedExpansionNavLinkContainer } from './link-container'
import { theme } from '../../../shared'

export interface IExpansionNavProps {
  onCloseClick: () => void
}

export const ExpansionNavDesktop = ({ onCloseClick }: IExpansionNavProps) => {
  return (
    <AnimateSharedLayout>
      <motion.div
        layout
        style={{
          position: 'inherit',
          overflow: 'hidden',
        }}
        initial={{
          backgroundColor: theme.colors.goldTint3,
          width: 483,
          zIndex: 10,
          paddingTop: 78,
          paddingLeft: 85,
          height: 0,
        }}
        animate={{
          height: '100vh',
          transition: {
            duration: 0.5,
          },
        }}
        exit={{
          height: 0,
          opacity: 0,
          transition: {
            duration: 0.5,
            delay: 0.5,
          },
        }}
      >
        <FlexColumnLayout
          sx={{
            maxWidth: 310,
          }}
        >
          <motion.div layout>
            <FlexRowLayout onClick={() => onCloseClick()} sx={{ cursor: 'pointer', marginBottom: 90 }}>
              <Image sx={{ height: 'auto', width: 'auto' }} src="/navbar/close.svg" alt="close" />
              <Text tag="body-bold" textColor={theme.colors.ui2} sx={{ paddingLeft: '8px' }}>
                {'Close'}
              </Text>
            </FlexRowLayout>
          </motion.div>
          <AnimatedExpansionNavLinkContainer />
        </FlexColumnLayout>
        <Text
          sx={{
            width: 320,
            position: 'fixed',
            bottom: 30,
            ...theme.fontstyles.legalDisclaimers,
          }}
          tag={['body-bold']}
        >
          NorthOne is a financial technology company, not a bank.
          <Box as="br" sx={{ display: ['none', 'block', 'block'] }} />
          Banking services provided by The Bancorp Bank, N.A.;
          <Box as="br" sx={{ display: ['none', 'block', 'block'] }} />
          Member FDIC.
        </Text>
      </motion.div>
    </AnimateSharedLayout>
  )
}
