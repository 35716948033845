export const FEATURE_LIST = [
  {
    header: 'A __smarter__ bank account',
    title: 'Mobile banking',
    description: 'Never visit a bank branch again. Simple but powerful banking tools that live on your phone.',
    imageSrc: '/static/images/product/features/smart-bank-account/features-mobilebanking.png',
    imageDesc: "NorthOne home screen showing the user's available balance and expenses",
  },
  {
    header: 'A __smarter__ bank account',
    title: 'Simple Payments',
    description: 'Sending payments has never been easier. Send ACH or Wire payment, and pay bills from your phone.',
    imageSrc: '/static/images/product/features/smart-bank-account/features-simplepayments.png',
    imageDesc: 'NorthOne payment screen for payees',
  },
  {
    header: 'A __smarter__ bank account',
    title: 'Mobile Check Deposits',
    description: 'No more waiting in line. Close the deal and deposit the check seconds later.',
    imageSrc: '/static/images/product/features/smart-bank-account/features-checkdeposits.png',
    imageDesc: 'a cheque on the deposit screen',
  },
  {
    header: 'A __smarter__ bank account',
    title: 'Cash Deposits',
    description:
      'Use your NorthOne Mastercard Debit Card to deposit or withdraw cash at any of the 300,000 NorthOne-network ATMs nationwide',
    imageSrc: '/static/images/product/features/smart-bank-account/features-cashdeposits.png',
    imageDesc: 'ATM Locations screen with NorthOne blue business debit card',
  },
  {
    header: 'Get your money right',
    title: 'Dedicated Tax Accounts',
    description: 'Putting money aside for taxes (or any major expense) is easy with dedicated accounts.',
    imageSrc: '/static/images/product/features/smart-bank-account/features-taxaccounts.png',
    imageDesc: "Accounts screen that shows 'checking' 'taxes' and 'rent' accounts",
  },
  {
    header: 'Get your money right',
    title: 'Cashflow reporting',
    description: 'Understand your revenue and spending. No finance degree needed.',
    imageSrc: '/static/images/product/features/smart-bank-account/features-cashflow.png',
    imageDesc: 'Insight feature screen for viewing revenue expenses and payment insights',
  },
  {
    header: 'Get your money right',
    title: 'Transaction search',
    description: 'Find any transaction across your whole account history with smart filters.',
    imageSrc: '/static/images/product/features/smart-bank-account/features-transactions.png',
    imageDesc: 'Transactions Screen for the customer to see their transaction history',
  },
]
