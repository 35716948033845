import React from 'react'
import { BaseLayout, TextBlock, NewImage, theme } from '../../../shared'
import { Box } from 'rebass'
import { fireWebsiteSignupEvent } from '../../../utils'
import { ONBOARDING_URL } from '../../../utils/urls'
import { FeaturesIntegrations } from './features-integrations'

export const FeaturesConnect = (): JSX.Element => (
  <Box>
    <BaseLayout
      marginTop="20px"
      bodyComponent={
        <TextBlock
          label={'integrations'}
          titleTag="h2"
          title={'__Connect__ to your favorite business apps'}
          paragraph={'Everything you need to run your business.'}
          paragraphStyle={{ ...theme.fontStyles[3] }}
          paragraphColor={theme.colors.ui1}
          ctaButton={{
            text: 'Apply for an account',
            type: 'blackPrimary',
            url: ONBOARDING_URL,
            onClick: fireWebsiteSignupEvent,
            buttonSx: {
              width: [285, 285, 235],
            },
          }}
        />
      }
      imageComponent={
        <NewImage
          alt="iPhone with NorthOne card displaying notification on a paypal deposit"
          src="/static/images/product/features/features-paidfaster.png"
        />
      }
    />
    <FeaturesIntegrations />
  </Box>
)
