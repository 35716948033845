import React from 'react'
import { TESTIMONIALS_CONTENT } from '../../components'
import { Box } from 'rebass'

interface ITestimonialButtonProps {
  index: number
  isButtonActive: boolean
  handleButtonClick: (index: number) => void
}
export const TestimonialButton = ({
  index,
  isButtonActive,
  handleButtonClick,
}: ITestimonialButtonProps): JSX.Element => (
  <Box
    sx={{
      width: `${isButtonActive ? '9px' : '7px'}`,
      height: `${isButtonActive ? '9px' : '7px'}`,
      borderRadius: '50%',
      backgroundColor: 'white',
      opacity: `${isButtonActive ? '1' : '0.5'}`,
      cursor: 'pointer',
      marginX: '3px',
    }}
    onClick={() => {
      handleButtonClick(index)
    }}
  />
)

export const TestimonialPagination = ({
  currentSlideIndex,
  handleButtonClick,
}: {
  currentSlideIndex: number
  handleButtonClick: (index: number) => void
}): JSX.Element => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '15px' }}>
      {TESTIMONIALS_CONTENT.map((_, index) => (
        <TestimonialButton
          key={index}
          index={index}
          isButtonActive={currentSlideIndex === index ? true : false}
          handleButtonClick={handleButtonClick}
        />
      ))}
    </Box>
  )
}
