export const FEATURES_SUPPORT_LIST = [
  {
    iconSrc: '/static/images/product/features/chat-icon.svg',
    iconDesc: 'chat bubble icon',
    title: 'In-app live chat',
    text: `Our experts respond within minutes.`,
  },
  {
    iconSrc: '/static/images/product/features/phone-icon.svg',
    iconDesc: 'phone icon',
    title: 'Tell us when to call you',
    text: `Don't waste your time in line or on hold.`,
  },
  {
    iconSrc: '/static/images/product/features/email-icon.svg',
    iconDesc: 'paper plane icon',
    title: 'Send an email',
    text: 'Expect a fast, human response.',
  },
]
