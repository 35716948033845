import React from 'react'
import { PrimaryLink, StandardPadding } from '../../shared'
import { Box, Link, SxStyleProp } from 'rebass'
import { Text, theme } from '@northone/ui'

export const TermsOfUseContent = (): JSX.Element => (
  <StandardPadding>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '60px',
      }}
    >
      <Text tag={'h1'}>Terms of Use</Text>
      <br />
      <br />
      <Text tag={'h2'}>1. Terms</Text>
      <br />
      <Text tag={'body-large'}>
        By accessing the website at <PrimaryLink href="/">http://www.northone.com</PrimaryLink>, you are agreeing to be
        bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for
        compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from
        using or accessing this site. The materials contained in this website are protected by applicable copyright and
        trademark law.
      </Text>
      <br />
      <br />
      <Text tag={'h2'}>2. Use License</Text>
      <br />
      <ol type="a">
        <Text tag="body-large">
          <li>
            Permission is granted to temporarily download one copy of the materials (information or software) on
            NorthOne&#39;s website for personal, non-commercial transitory viewing only. This is the grant of a license,
            not a transfer of title, and under this license you may not:
            <ol type="i">
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
              </li>
              <li>attempt to decompile or reverse engineer any software contained on NorthOne&#39;s website;</li>
              <li>remove any copyright or other proprietary notations from the materials; or</li>
              <li>transfer the materials to another person or “mirror” the materials on any other server.</li>
            </ol>
            <br />
          </li>
          <li>
            This license shall automatically terminate if you violate any of these restrictions and may be terminated by
            NorthOne at any time. Upon terminating your viewing of these materials or upon the termination of this
            license, you must destroy any downloaded materials in your possession whether in electronic or printed
            format.
          </li>
        </Text>
      </ol>
      <br />
      <br />
      <Text tag={'h2'}>3. Disclaimer</Text>
      <br />
      <ol type="a">
        <Text tag="body-large">
          <li>
            The materials on NorthOne&#39;s website are provided on an ‘as is’ basis. NorthOne makes no warranties,
            expressed or implied, and hereby disclaims and negates all other warranties including, without limitation,
            implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement
            of intellectual property or other violation of rights.
          </li>
          <li>
            Further, NorthOne does not warrant or make any representations concerning the accuracy, likely results, or
            reliability of the use of the materials on its website or otherwise relating to such materials or on any
            sites linked to this site.
          </li>
        </Text>
      </ol>
      <br />
      <br />
      <Text tag={'h2'}>4. Limitations</Text>
      <br />
      <Text tag={'body-large'}>
        In no event shall NorthOne or its suppliers be liable for any damages (including, without limitation, damages
        for loss of data or profit, or due to business interruption) arising out of the use or inability to use the
        materials on NorthOne&#39;s website, even if NorthOne or a NorthOne authorized representative has been notified
        orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on
        implied warranties, or limitations of liability for consequential or incidental damages, these limitations may
        not apply to you.
      </Text>
      <br />
      <br />
      <Text tag={'h2'}>5. Accuracy of materials</Text>
      <br />
      <Text tag={'body-large'}>
        The materials appearing on NorthOne&#39;s website could include technical, typographical, or photographic
        errors. NorthOne does not warrant that any of the materials on its website are accurate, complete or current.
        NorthOne may make changes to the materials contained on its website at any time without notice. However NorthOne
        does not make any commitment to update the materials.
      </Text>
      <br />
      <br />
      <Text tag={'h2'}>6. Links</Text>
      <br />
      <Text tag={'body-large'}>
        NorthOne has not reviewed all of the sites linked to its website and is not responsible for the contents of any
        such linked site. The inclusion of any link does not imply endorsement by NorthOne of the site. Use of any such
        linked website is at the user&#39;s own risk.
      </Text>
      <br />
      <br />
      <Text tag={'h2'}>7. Modifications</Text>
      <br />
      <Text tag={'body-large'}>
        NorthOne may revise these terms of service for its website at any time without notice. By using this website you
        are agreeing to be bound by the then current version of these terms of service.
      </Text>
      <br />
      <br />
      <Text tag={'h2'}>8. Governing Law</Text>
      <br />
      <Text tag={'body-large'}>
        These terms and conditions are governed by and construed in accordance with the laws of New York and you
        irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
      </Text>
    </Box>
  </StandardPadding>
)

const linkSx: SxStyleProp = { color: theme.colors.ui1, textDecoration: 'underline', wordBreak: 'break-word' }

export const PrivacyPolicyContent = (): JSX.Element => (
  <StandardPadding>
    <Box sx={{ width: '100%', lineHeight: '20px', paddingBottom: '60px' }}>
      <h1>
        <strong>NORTHONE, INC. PRIVACY AND COOKIE POLICY</strong>
      </h1>
      <p>
        <strong>LAST UPDATED: JANUARY 2021</strong>
      </p>

      <br />
      <p>
        <strong>Introduction</strong>
      </p>
      <p>
        This privacy and cookie policy (“<strong>Policy</strong>”) describes how NorthOne, Inc. (“
        <strong>NorthOne,</strong>” “<strong>we,</strong>” and “<strong>our</strong>”) collects, uses and shares your
        personal data when you use NorthOne’s applications, including our application known as “NorthOne” (each, a “
        <strong>App</strong>” and collectively, the “<strong>Apps</strong>”) and the services provided in connection
        therewith (the “<strong>Services</strong>”). Our Services act as a portal or interface that enables users to
        access certain financial products and banking services made available by NorthOne’s partnering financial
        institution (the “<strong>Bank</strong>”). Please read the following information carefully to understand our
        views and practices regarding your personal data and how we will treat it.
      </p>
      <br />
      <p>
        <strong>Particularly Important Information</strong>
      </p>
      <p>
        <strong>Who we are: </strong>For the purpose of applicable data protection legislation, the data controller of
        your personal data is <strong>NorthOne, Inc. of</strong> 18 W 18th St, New York, NY 10011.
      </p>
      <p>
        <strong>
          BANK INFORMATION: You may contact NorthOne at any time to request information about the Bank by emailing us at
        </strong>{' '}
        <Link href="mailto:support@northone.com" sx={linkSx}>
          <strong>support@northone.com</strong>
        </Link>
        <strong>. </strong>
      </p>
      <p>
        <strong>Must Read Sections: </strong>We draw your attention in particular to the sections entitled “
        <strong>International Data Transfer</strong>”<strong> </strong>and “<strong>Your Rights.</strong>”
      </p>
      <p>
        <strong>Changes to this Policy: </strong>We will post any modifications or changes to the Policy on our
        Apps/Services. We reserve the right to modify the Policy at any time, so we encourage you to review it
        frequently. The “Last Updated” legend above indicates when this Policy was last changed. If we make any material
        change(s) to the Policy, we will notify you via email and/or post a notice on our Apps/Services prior to such
        changes(s) taking effect.
      </p>
      <br />
      <p>
        <strong>1.&nbsp; &nbsp; &nbsp; PURPOSES OF PROCESSING </strong>
      </p>
      <p>
        <strong>What is personal data?</strong>
      </p>
      <p>
        We collect information about you in a range of forms, which could include any information which, either alone or
        in combination with other information we hold about you, identifies you as an individual, for example, your
        name, date of birth, social security number, postal address, email address and telephone number.
      </p>
      <p>
        <strong>Why do we need your personal data?</strong>
      </p>
      <p>
        We need certain personal data in order to provide you with access to the Apps/Services. We will only process
        your personal data in accordance with applicable data protection and privacy laws.
      </p>
      <br />
      <p>
        <strong>2.&nbsp; &nbsp; &nbsp;COLLECTING YOUR PERSONAL DATA</strong>
      </p>
      <p>We collect information about you in the following ways:</p>
      <p>
        <strong>Information You Give Us.</strong> This includes:
      </p>
      <ul>
        <li>
          the personal data you provide when you sign-up for, and/or login to, your account, such biometric data used
          for facial and/or touch/fingerprint ID recognition purposes, as well as any other information you submit
          during the registration process, including name, email, and company name;
        </li>
        <li>
          the personal data you provide when you access and use the financial and banking products and services made
          available by the Bank via our Apps/Services;
        </li>
        <li>
          the personal data you provide when you report a problem with our Apps/Services or when we provide you with
          customer support; and
        </li>
        <li>the personal data you provide when you correspond with us by email or otherwise.</li>
      </ul>
      <p>
        <strong>Information We Get from the Bank.</strong> We may also get information about you from the Bank, for
        example, your financial accountant information and transaction history, in order to provide you the Services and
        information you request from the Bank.<strong> </strong>
      </p>
      <p>
        <strong>Information Automatically Collected.</strong> We automatically log information about you and your
        computer or mobile device when you access our Apps/Services. For example, when visiting our Apps/Services, we
        log your computer or mobile device operating system name and version, manufacturer and model, browser type,
        browser language, screen resolution, the website you visited before browsing to our Apps/Services, pages you
        viewed, how long you spent on a page, access times and information about your use of and actions on our
        Apps/Services. We collect this information about you using cookies. Please refer to the sections on cookies.
      </p>
      <p>
        <strong>Automated Decision Making and Profiling. </strong>
      </p>
      <p>
        We may use automated decision making and/or profiling in regard to your personal data for some services and
        products, for example if you opt-in to see if you pre-qualify for certain financial products of the Bank, we may
        use the information you provide to automatically determine whether you pre-qualify for such products. You can
        request a manual review of the accuracy of an automated decision that you are unhappy with or limit or object to
        such automated decision making and/or profiling by contacting us at: support@northone.com.
      </p>
      <br />
      <p>
        <strong>3.&nbsp; &nbsp; &nbsp; COOKIES </strong>
      </p>
      <p>
        <strong>What are cookies?</strong>
      </p>
      <p>
        We may collect information using “cookies.” Cookies are small data files stored on the hard drive of your
        computer or mobile device by a website. We may use both session cookies (which expire once you close your web
        browser) and persistent cookies (which stay on your computer or mobile device until you delete them) to help
        analyze how you use our Apps/Services to improve their design, efficiency and use.
      </p>
      <p>
        We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or
        mobile device, which are used only by us to recognize your computer or mobile device when it revisits our
        Apps/Services; and (2) third party cookies, which are served by service providers on our Apps/Services, and can
        be used by such service providers to recognize your computer or mobile device when it visits other websites.
      </p>
      <p>
        <strong>Cookies we use</strong>
      </p>
      <p>Our Apps/Services use the following types of cookies for the purposes set out below:</p>
      <Box
        sx={{
          width: '100%',
          maxWidth: '1440px',
          display: 'grid',
          gridTemplateColumns: ['100px auto', '100px auto', '150px auto'],
          gridTemplateRows: '50px repeat(5, auto)',
        }}
      >
        <Box sx={{ gridRow: '1/2', gridColumn: '1/2' }}>
          <p>
            <strong>Type of cookie</strong>
          </p>
        </Box>
        <Box sx={{ gridRow: '1/2', gridColumn: '2/-1' }}>
          <p>
            <strong>Purpose</strong>
          </p>
        </Box>
        <Box sx={{ gridRow: '2/3', gridColumn: '1/2' }}>
          <p>
            <em>Essential Cookies </em>
          </p>
        </Box>
        <Box sx={{ gridRow: '2/3', gridColumn: '2/-1' }}>
          <p>
            These cookies are essential to provide you with services available through our Apps/Services and to enable
            you to use some of its features. For example, they allow us to identify whether you have downloaded the App,
            or determine whether you qualify for certain features offered through the Apps/Services.Without these
            cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide
            you with those services.
          </p>
        </Box>
        <Box sx={{ gridRow: '3/4', gridColumn: '1/2' }}>
          <p>
            <em>Functionality Cookies</em>
          </p>
        </Box>
        <Box sx={{ gridRow: '3/4', gridColumn: '2/-1' }}>
          <p>
            These cookies allow our Apps/Services to remember choices you make when you use our Apps/Services, such as
            remembering your language preferences, remembering your login details and remembering the changes you make
            to other parts of our Apps/Services which you can customize. The purpose of these cookies is to provide you
            with a more personal experience and to avoid you having to re-enter your preferences every time you visit
            our Apps/Services.
          </p>
        </Box>
        <Box sx={{ gridRow: '4/5', gridColumn: '1/2' }}>
          <p>
            <em>Analytics and Performance Cookies</em>
          </p>
        </Box>
        <Box sx={{ gridRow: '4/5', gridColumn: '2/-1' }}>
          <p>
            These cookies are used to collect information about traffic to our Apps/Services and how users use our
            Apps/Services. The information gathered does not identify any individual visitor. It includes the number of
            visitors to our Apps/Services, the websites that referred them to our Apps/Services, the pages they visited
            on our Apps/Services, what time of day they visited our Apps/Services, whether they have visited our
            Apps/Services before, and other similar information. We use this information to help operate our
            Apps/Services more efficiently, to gather broad demographic information and to monitor the level of activity
            on our Apps/Services.
          </p>
          <p>We use Google Analytics, Mixpanel and Segment for this purpose. </p>
          <p>
            Google Analytics, Mixpanel and Segment each use their own cookies. These cookies are only used to improve
            how our Apps/Services works.
          </p>
          <p>
            You can find out more information about Google Analytics cookies here: &nbsp;
            <Link
              href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies"
              rel="noopener noreferrer"
              target="_blank"
              sx={linkSx}
            >
              https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
            </Link>
          </p>
          <p>
            You can find out more about how Google protects your data here: &nbsp;
            <Link
              href="http://www.google.com/analytics/learn/privacy.html"
              rel="noopener noreferrer"
              target="_blank"
              sx={linkSx}
            >
              www.google.com/analytics/learn/privacy.html
            </Link>
            .
          </p>
          <p>
            You can prevent the use of Google Analytics relating to your use of our Apps/Services by downloading and
            installing the browser plugin available via this link: &nbsp;
            <Link
              href="http://tools.google.com/dlpage/gaoptout?hl=en-GB"
              rel="noopener noreferrer"
              target="_blank"
              sx={linkSx}
            >
              http://tools.google.com/dlpage/gaoptout?hl=en-GB
            </Link>
          </p>
          <p>
            You can find out more information about how Mixpanel processes your data here: &nbsp;
            <Link
              href="https://mixpanel.com/legal/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
              sx={linkSx}
            >
              https://mixpanel.com/legal/privacy-policy
            </Link>
          </p>
          <p>
            You can find out more information about how Segment processes your data here: &nbsp;
            <Link href="https://segment.com/docs/legal/privacy" rel="noopener noreferrer" target="_blank" sx={linkSx}>
              https://segment.com/docs/legal/privacy.
            </Link>
          </p>
        </Box>
        <Box sx={{ gridRow: '5/6', gridColumn: '1/2' }}>
          <p>
            <em>Referral cookies</em>
          </p>
        </Box>
        <Box sx={{ gridRow: '5/6', gridColumn: '2/-1' }}>
          <p>
            We may place cookies on the websites of our third party partners to track those users who click on links to
            our website and/or download our Apps. We use this information to help identify the effectiveness of our
            partners’ links and comply with our requirements with such partners.
          </p>
        </Box>
      </Box>
      <p>
        <strong>Disabling cookies</strong>
      </p>
      <p>
        You can typically remove or reject cookies via your browser settings. In order to do this, follow the
        instructions provided by your browser (usually located within the “settings,” “help” “tools” or “edit”
        facility). Many browsers are set to accept cookies until you change your settings.
      </p>
      <p>
        Further information about cookies, including how to see what cookies have been set on your computer or mobile
        device and how to manage and delete them, visit &nbsp;
        <Link href="http://www.allaboutcookies.org" rel="noopener noreferrer" target="_blank" sx={linkSx}>
          www.allaboutcookies.org
        </Link>{' '}
        and &nbsp;
        <Link href="http://www.youronlinechoices.com.uk" rel="noopener noreferrer" target="_blank" sx={linkSx}>
          www.youronlinechoices.com.uk
        </Link>
        .
      </p>
      <p>
        If you do not accept our cookies, you may experience some inconvenience in your use of our Apps/Services. For
        example, we may not be able to recognize your device and you may need to answer challenge questions or provide
        additional information each time you log on.
      </p>
      <br />
      <p>
        <strong>4.&nbsp; &nbsp; &nbsp; DO NOT TRACK SIGNALS</strong>
      </p>
      <p>
        Some Internet browsers may be configured to send &quot;Do Not Track&quot; signals to the online services that
        you visit. We currently do not respond to do not track signals. To find out more about &quot;Do Not Track,&quot;
        please visit&nbsp;
        <Link href="http://www.allaboutdnt.com" rel="noopener noreferrer" target="_blank" sx={linkSx}>
          http://www.allaboutdnt.com
        </Link>
        .
      </p>
      <br />
      <p>
        <strong>5.&nbsp; &nbsp; &nbsp; USING YOUR PERSONAL DATA</strong>
      </p>
      <p>We may use your personal data as follows:</p>
      <ul>
        <li>to operate, maintain, and improve our Apps/Services, products, and services;</li>
        <li>to respond to your comments and questions and to provide customer service;</li>
        <li>to recommend, and/or prequalify you for, certain financial and banking products and services to you;</li>
        <li>
          to send information including technical notices, updates, security alerts, and support and administrative
          messages;
        </li>
        <li>
          with your consent, to send you marketing e-mails and/or text messages about upcoming promotions, and other
          news, including information about products and services offered by us and our affiliates. You may opt-out of
          receiving such information at any time: such marketing emails and text messages tell you how to “opt-out.”
          Please note, even if you opt out of receiving marketing emails, we may still send you non-marketing emails.
          Non-marketing emails include emails about your account with us (if you have one) and our business dealings
          with you;
          <strong> </strong>
        </li>
        <li>
          as we believe necessary or appropriate (a) to comply with applicable laws; (b) to comply with lawful requests
          and legal process, including to respond to requests from public and government authorities; (c) to enforce our
          Policy; and (d) to protect our rights, privacy, safety or property, and/or that of you or others; and
        </li>
        <li>as described in the “Sharing of your Personal Data” section below.</li>
      </ul>
      <br />
      <p>
        <strong>6.&nbsp; &nbsp; &nbsp; SHARING YOUR PERSONAL DATA</strong>
      </p>
      <p>We may share your personal data as follows:</p>
      <ul>
        <li>
          <strong>With the Bank</strong>. Our Service acts as a portal/interface between you and the Bank. As such, we
          will share the personal data you provide via the Apps/Services with the Bank so that the Bank may provide you
          with the products and services you request, and to comply with any audit requests of the Bank. By using our
          Services, you acknowledge and agree that the Bank’s use of your personal data will be subject to its Privacy
          Policy. We encourage you to read the Bank’s Privacy Policy in its entirety. Please contact us at &nbsp;
          <Link href="mailto:support@northone.com" sx={linkSx}>
            support@northone.com
          </Link>{' '}
          if you need assistance accessing the Bank’s Privacy Policy or other information about the Bank.
          <strong>Permission Based Sharing.</strong> We may share read-only (i.e. only permitted to view and not make
          any changes) access to your personal data with those persons you designate. For example, if you instruct us to
          provide your accountant with read-only access to your account, we will provide your accountant with such
          access for the time period(s) you designate. You may revoke any permissions you’ve granted at any time.
        </li>
        <li>
          <strong>Our</strong> <strong>Third Party Service Providers</strong>. We may share your personal data with our
          third party service providers who provide services such as data analysis, payment processing, information
          technology and related infrastructure provision, customer service, email delivery, auditing and other similar
          services. These third parties are only permitted to use your personal data to the extent necessary to enable
          them to provide their services to us. They are required to follow our express instructions and to comply with
          appropriate security measures to protect your personal data.
        </li>
        <li>
          <strong>Third Party Advertisers</strong>. We may share personal data with advertisers that require the data to
          identify new target audiences for our Apps/Services.
        </li>
        <li>
          <strong>Affiliates</strong>. We may share some or all of your personal data with our affiliates, in which case
          we will require our affiliates to comply with this Policy. In particular, you may let us share personal data
          with our affiliates where you wish to receive marketing communications from them.<strong> </strong>
        </li>
        <li>
          <strong>Corporate Restructuring.</strong> We may share personal data when we do a business deal, or negotiate
          a business deal, involving the sale or transfer of all or a part of our business or assets. These deals can
          include any merger, financing, acquisition, or bankruptcy transaction or proceeding.
        </li>
        <li>
          <strong>Other Disclosures.</strong> We may share personal data as we believe necessary or appropriate: (a) to
          comply with applicable laws; (b) to comply with lawful requests and legal process, including to respond to
          requests from public and government authorities to meet national security or law enforcement requirements; (c)
          to enforce our Policy; and (d) to protect our rights, privacy, safety or property, and/or that of you or
          others.
          <strong> </strong>
        </li>
      </ul>
      <br />
      <p>
        <strong>7.&nbsp; &nbsp; &nbsp; ANONYMOUS DATA</strong>
      </p>
      <p>
        When we use the term “anonymous data,” we are referring to data and information that does not permit you to be
        identified or identifiable, either alone or when combined with any other information available to a third party.
      </p>
      <p>
        We may create anonymous data from the personal data we receive about you and other individuals whose personal
        data we collect. Anonymous data might include analytics information and information collected by us using
        cookies.We make personal data into anonymous data by excluding information (such as your name) that makes the
        data personally identifiable to you. We use this anonymous data to analyses usage patterns in order to make
        improvements to our Apps/Services.
      </p>
      <br />
      <p>
        <strong>8.&nbsp; &nbsp; &nbsp; USER GENERATED CONTENT </strong>
      </p>
      <p>
        If you provide feedback to us, we may use and disclose such feedback on our Apps/Services, provided we do not
        associate such feedback with your personal data. If you have provided your consent to do so, we may post your
        first name and last initial along with your feedback on our Apps/Services. We will collect any information
        contained in such feedback and will treat the personal data in it in accordance with this Policy.
        <strong> </strong>
      </p>
      <br />
      <p>
        <strong>9.&nbsp; &nbsp; &nbsp; INTERNATIONAL DATA TRANSFER </strong>
      </p>
      <p>
        Your information, including personal data that we collect from you, may be transferred to, stored at and
        processed by us and the Bank outside the country in which you reside, including, but not limited to the United
        States, where data protection and privacy regulations may not offer the same level of protection as in other
        parts of the world. By using our App(s)/ Services, you agree to this transfer, storing or processing. We will
        take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this
        Policy.
      </p>
      <br />
      <p>
        <strong>10.&nbsp; &nbsp; &nbsp; SECURITY</strong>
      </p>
      <p>
        We seek to use reasonable organizational, technical and administrative measures to protect personal data within
        our organization. Unfortunately, no transmission or storage system can be guaranteed to be completely secure,
        and transmission of information via the internet is not completely secure. If you have reason to believe that
        your interaction with us is no longer secure (for example, if you feel that the security of any account you
        might have with us has been compromised), please immediately notify us of the problem by contacting us using the
        details in Section 15 below.
      </p>
      <br />
      <p>
        <strong>11.&nbsp; &nbsp; &nbsp; RETENTION</strong>
      </p>
      <p>
        We will retain your personal data (i) as long as reasonably required for you to use the Apps and/or to provide
        you with the Services; (ii) in order to improve our services; and/or (iii) as required by applicable law or for
        regulatory purposes.
      </p>
      <br />
      <p>
        <strong>12.&nbsp; &nbsp; &nbsp; SENSITIVE PERSONAL DATA</strong>
      </p>
      <p>
        If you send or disclose any sensitive personal data to us when you log-in to your account, you consent to our
        processing and use of such sensitive personal data in accordance with this Policy. If you do not consent to our
        processing and use of such sensitive personal data, you will not be able to create or log-in to an account.
      </p>
      <br />
      <p>
        <strong>13.&nbsp; &nbsp; &nbsp; YOUR RIGHTS </strong>
      </p>
      <ul>
        <li>
          <strong>Opt-out. </strong> You may contact us anytime to opt-out of: (i) direct marketing communications; (ii)
          automated decision-making and/or profiling (except in the case of automated decision-making for fraud
          prevention purposes); or (iii) any new processing of your personal data that we may carry out beyond the
          original purpose. Please note that your use of some of the Apps/Services may be ineffective upon opt-out.
        </li>
        <li>
          <strong>Amend. </strong>You can also contact us to update or correct any inaccuracies in your personal data.
        </li>
        <li>
          <strong>Move. </strong>Your banking transaction data is portable – i.e. you to have the flexibility to move
          your data to other service providers as you wish.
        </li>
        <li>
          <strong>Erase and forget. </strong>In certain situations, for example when the information we hold about you
          is no longer relevant or is incorrect, you can request that we erase your data.
        </li>
      </ul>
      <p>
        If you wish to exercise any of these rights, please contact us using the details in Section 15 below. In your
        request, please make clear: (i) <strong>what</strong> personal data is concerned; and (ii){' '}
        <strong>which of the above rights</strong> you would like to enforce. For your protection, we may only implement
        requests with respect to the personal data associated with the particular email address that you use to send us
        your request, and we may need to verify your identity before implementing your request. We will try to comply
        with your request as soon as reasonably practicable and in any event, within one month of your request. Please
        note that we may need to retain certain information for regulatory or recordkeeping purposes and/or to complete
        any transactions that you began prior to requesting such change or deletion.
      </p>
      <br />
      <p>
        <strong>14.&nbsp; &nbsp; &nbsp; COMPLAINTS</strong>
      </p>
      <p>
        We are committed to resolve any complaints about our collection or use of your personal data. If you would like
        to make a complaint regarding this Policy or our practices in relation to your personal data, please contact us
        at: &nbsp;
        <Link href="mailto:support@northone.com" sx={linkSx}>
          support@northone.com
        </Link>
        . We will reply to your complaint as soon as we can and in any event, within forty-five (45) days.
      </p>
      <br />
      <p>
        <strong>15.&nbsp; &nbsp; &nbsp; CONTACT INFORMATION</strong>
      </p>
      <p>
        We welcome your comments or questions about this Policy. You may contact us in writing at &nbsp;
        <Link href="mailto:support@northone.com" sx={linkSx}>
          support@northone.com
        </Link>{' '}
        or 1050 King St W, Toronto, ON M6K 0C7, Canada.
      </p>
    </Box>
  </StandardPadding>
)

export const BancorpPrivacyPolicyContent = (): JSX.Element => (
  <StandardPadding>
    <Box sx={{ width: '100%', lineHeight: '20px', paddingBottom: '60px' }}>
      <h1>
        <strong>BANCORP, INC. PRIVACY AND COOKIE POLICY</strong>
      </h1>
      <p>
        <strong>LAST UPDATED: JANUARY 2021</strong>
      </p>

      <br />
      <p>
        <strong>What does The Bancorp do with your personal information?</strong>
      </p>
      <p>
        <strong>
          <br />
          Why?
        </strong>
      </p>
      <p>
        Financial companies choose how they share your personal information. Federal law gives consumers the right to
        limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your
        personal information. Please read this notice carefully to understand what we do.
      </p>
      <p>
        <strong>What?</strong>
      </p>
      <p>
        The types of personal information we collect, and share depend on the product or service you have with us. This
        information can include:
      </p>
      <p> </p>
      <ul>
        <li>Social Security number and income</li>
        <li>Account balances and transaction history</li>
        <li>Credit history and credit scores</li>
      </ul>
      <p>
        When you are <em>no longer </em>our customer, we continue to share your information as described in this notice.
      </p>
      <p>
        <strong>How?</strong>
      </p>
      <p>
        All ﬁnancial companies need to share customers’ personal information to run their everyday business. In the
        section below, we list the reasons ﬁnancial companies can share their customers’ personal information; the
        reasons The Bancorp chooses to share; and whether you can limit this sharing.
      </p>
      <Box
        sx={{
          width: '100%',
          maxWidth: '1440px',
          display: 'grid',
          columnGap: '8px',
          gridTemplateColumns: ['auto 100px 100px', 'auto 100px 100px', 'auto 200px 200px', 'auto 250px 250px'],
          gridTemplateRows: ['150px repeat(7, auto)', '100px repeat(7, auto)', '50px repeat(7, auto)'],
        }}
      >
        <Box sx={{ gridRow: '1/2', gridColumn: '1/2' }}>
          <p>
            <strong>Reasons we can share your personal information</strong>
          </p>
        </Box>
        <Box sx={{ gridRow: '1/2', gridColumn: '2/3' }}>
          <p>
            <strong>Does The Bancorp share?</strong>
          </p>
        </Box>
        <Box sx={{ gridRow: '1/2', gridColumn: '3/-1' }}>
          <p>
            <strong>Can you limit this sharing?</strong>
          </p>
        </Box>
        <Box sx={{ gridRow: '2/3', gridColumn: '1/2' }}>
          <p>
            <strong>For our everyday business purposes—</strong>
          </p>
          <p>
            such as to process your transactions, maintain your account(s), respond to court orders and legal
            investigations, or report to credit bureaus
          </p>
        </Box>

        <Box sx={{ gridRow: '2/3', gridColumn: '2/3', alignSelf: 'center' }}>
          <p>Yes</p>
        </Box>
        <Box sx={{ gridRow: '2/3', gridColumn: '3/-1', alignSelf: 'center' }}>
          <p>No</p>
        </Box>

        <Box sx={{ gridRow: '3/4', gridColumn: '1/2' }}>
          <p>
            <strong>For our marketing purposes—</strong>
          </p>
          <p>to offer our products and services to you</p>
        </Box>
        <Box sx={{ gridRow: '3/4', gridColumn: '2/3', alignSelf: 'center' }}>
          <p>Yes</p>
        </Box>
        <Box sx={{ gridRow: '3/4', gridColumn: '3/-1', alignSelf: 'center' }}>
          <p>No</p>
        </Box>
        <Box sx={{ gridRow: '4/5', gridColumn: '1/2' }}>
          <p>
            <strong>For joint marketing with other ﬁnancial companies</strong>
          </p>
        </Box>
        <Box sx={{ gridRow: '4/5', gridColumn: '2/3', alignSelf: 'center' }}>
          <p>No</p>
        </Box>
        <Box sx={{ gridRow: '4/5', gridColumn: '3/-1', alignSelf: 'center' }}>
          <p>We don’t share</p>
        </Box>
        <Box sx={{ gridRow: '5/6', gridColumn: '1/2' }}>
          <p>
            <strong>For our afﬁliates’ everyday business purposes—</strong>
          </p>
          <p>information about your transactions and experiences</p>
        </Box>
        <Box sx={{ gridRow: '5/6', gridColumn: '2/3', alignSelf: 'center' }}>
          <p>No</p>
        </Box>
        <Box sx={{ gridRow: '5/6', gridColumn: '3/-1', alignSelf: 'center' }}>
          <p>We don’t share</p>
        </Box>
        <Box sx={{ gridRow: '6/7', gridColumn: '1/2' }}>
          <p>
            <strong>For our afﬁliates’ everyday business purposes—</strong>
          </p>
          <p>information about your creditworthiness</p>
        </Box>
        <Box sx={{ gridRow: '6/7', gridColumn: '2/3', alignSelf: 'center' }}>
          <p>No</p>
        </Box>
        <Box sx={{ gridRow: '6/7', gridColumn: '3/-1', alignSelf: 'center' }}>
          <p>We don’t share</p>
        </Box>
        <Box sx={{ gridRow: '7/-1', gridColumn: '1/2' }}>
          <p>
            <strong>For nonafﬁliates to market to you</strong>
          </p>
        </Box>
        <Box sx={{ gridRow: '7/-1', gridColumn: '2/3', alignSelf: 'center' }}>
          <p>No</p>
        </Box>
        <Box sx={{ gridRow: '7/-1', gridColumn: '3/-1', alignSelf: 'center' }}>
          <p>We don’t share</p>
        </Box>
      </Box>
      <br />
      <p>
        <strong>Questions?</strong>
      </p>
      <p>
        <Link href="https://thebancorp.com/support/thebancorpbank/privacy-faqs/" sx={linkSx}>
          <strong>https://thebancorp.com/support/thebancorpbank/privacy-faqs/</strong>
        </Link>
      </p>
      <p>
        <strong>Who we are</strong>
      </p>
      <p>Who is providing this notice? </p>
      <p>
        This notice is provided by the business units of The Bancorp, Inc., and its affiliates, including, but not
        limited to, The Bancorp Bank, N.A., Bancorp Card Services, Inc., and TBBK Direct Leasing, LLC.
      </p>
      <p>
        <strong>What we do</strong>
      </p>
      <p>How does The Bancorp protect my personal information? </p>
      <p>
        To protect your personal information from unauthorized access and use, we use security measures that comply with
        federal law. These measures include computer safeguards and secured ﬁles and buildings. We also limit access to
        information to those employees for whom access is necessary.{' '}
      </p>
      <p>How does The Bancorp collect my personal information? </p>
      <p>
        We collect your personal information, for example, when you open an account or apply for a loan pay your bills
        or make a wire transfer provide account information. We also collect your personal information from others, such
        as credit bureaus, affiliates, or other companies.{' '}
      </p>
      <p>Why can’t I limit all sharing?</p>
      <p>
        Federal law gives you the right to limit only sharing for afﬁliates’ everyday business purposes— information
        about your creditworthiness afﬁliates from using your information to market to you sharing for nonafﬁliates to
        market to you State laws and individual companies may give you additional rights to limit sharing.
      </p>
      <p>Deﬁnitions </p>
      <p>
        Afﬁliates: Companies related by common ownership or control. They can be ﬁnancial and nonﬁnancial companies. Our
        affiliates include companies such as The Bancorp Bank, N.A., Bancorp Card Services, Inc., and TBBK Direct
        Leasing, LLC.{' '}
      </p>
      <p>
        Nonafﬁliates: Companies not related by common ownership or control. They can be ﬁnancial and nonﬁnancial
        companies. The Bancorp does not share with nonaffiliates so they can market to you.{' '}
      </p>
      <p>
        Joint marketing: A formal agreement between nonafﬁliated ﬁnancial companies that together market ﬁnancial
        products or services to you. The Bancorp doesn’t jointly market.
      </p>
      <br />

      <p>
        <strong>Other Important Information</strong>
      </p>
      <Box
        sx={{
          width: '100%',
          maxWidth: '1440px',
          display: 'grid',
          gridTemplateColumns: ['100px auto', '100px auto', '200px auto'],
          gridTemplateRows: 'repeat(4, auto)',
        }}
      >
        <Box sx={{ gridRow: '1/2', gridColumn: '1/2' }}>
          <p>Vermont Residents:</p>
        </Box>
        <Box sx={{ gridRow: '1/2', gridColumn: '2/3' }}>
          <p>
            In accordance with Vermont law, we will not share personal information about you other than transaction
            experience information, with other Bancorp companies or affiliates, nor will we share any personal financial
            information about you with other Bancorp companies for marketing purposes.
          </p>
        </Box>
        <Box sx={{ gridRow: '2/3', gridColumn: '1/2' }}>
          <p>Nevada Residents:</p>
        </Box>
        <Box sx={{ gridRow: '2/3', gridColumn: '2/3' }}>
          <p>
            Nevada law requires that we provide you with the following contact information: Bureau of Consumer
            Protection, Office of the Nevada Attorney General, 555 E. Washington Ave., Suite 3900, Las Vegas, NV 89101;
            Phone number: 702-486-3132; email: agInfo@ag.nv.gov
          </p>
        </Box>
        <Box sx={{ gridRow: '3/4', gridColumn: '1/2' }}>
          <p>California Residents:</p>
        </Box>
        <Box sx={{ gridRow: '3/4', gridColumn: '2/3' }}>
          <p>
            Effective January 1, 2020, the California Consumer Privacy Act (CCPA) permits consumers who are California
            residents to (a) ask a covered business which categories and pieces of personal information it collects and
            how the information is used; (b) request deletion of the information; and (c) opt out of the sale of such
            information, if applicable. These provisions of the CCPA do not apply to personal information collected,
            processed, shared, or disclosed by financial institutions pursuant to federal law.
          </p>
          <p>
            To contact us with questions about our compliance with the CCPA, call 1-833-981-1080; visit our website:
            thebancorpbank.com; or write to: The Bancorp/CCPA, PO Box 5017,
          </p>
          <p>Sioux Falls, SD 57117-5017.</p>
        </Box>
      </Box>
    </Box>
  </StandardPadding>
)
