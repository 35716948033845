import React from 'react'
import { TextBlockList, BaseLayout, TextBlock, NewImage } from '../../shared'

const SECURITY_LIST = [
  {
    imageSrc: '/static/images/open-banking/open-banking-developer-icon.svg',
    title: 'An API banking platform for developers, by developers',
    paragraph: 'Our backend is being developed by some of the top talent in the industry.',
    imgDesc: 'Open banking developer icon',
  },
  {
    imageSrc: '/static/images/open-banking/open-banking-security-icon.svg',
    title: 'Our business is security',
    paragraph:
      'Let us worry about security so you can focus on building in a brand new ecosystem of banking applications.',
    imgDesc: 'Security shield icon',
  },
  {
    imageSrc: '/static/images/open-banking/open-banking-build-icon.svg',
    title: 'Build on the bank account',
    paragraph:
      'Easy to understand guides help you extend the functionality of your bank account and connect it to the world of financial services online—even your own apps!',
    imgDesc: 'Stack icon',
  },
]

export const OpenBankingList = (): JSX.Element => (
  <BaseLayout
    containerSx={{ paddingBottom: 60 }}
    reverse
    imageComponent={
      <NewImage
        src="/static/images/open-banking/openbanking-api.png"
        mobileSrc="/static/images/open-banking/openbanking-api-mobile.png"
        alt="Phone with multiple wires"
      />
    }
    bodyComponent={
      <TextBlock
        containerSx={{ display: 'flex', justifyContent: 'flex-start' }}
        title={`__API banking__ platform for small businesses
        `}
        titleTag="h2"
        contentBlockJSX={SECURITY_LIST.map((item, index) => (
          <TextBlockList
            verticallyCenterImage={false}
            key={index}
            imageSrc={item.imageSrc}
            imageAlt={item.imgDesc}
            title={item.title}
            paragraph={item.paragraph}
            customIconHeight="70px"
            customWidth="100%"
          />
        ))}
      />
    }
  />
)
