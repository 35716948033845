export interface ITeamMember {
  name: string
  fullName: string
  description: string
  imageSrc: string
  imageDesc: string
  link: string
  body: string
  personalInfo: ITeamMemberPersonalInfo
  socialMedia: ITeamMemberSocialMedia
}

export interface ITeamMemberSocialMedia {
  twitter: string
  linkedIn: string
}

export interface ITeamMemberPersonalInfo {
  homeTown: string
  inspiration: string
  book: string
  podcast: string
}

export interface IArticleItemProps {
  imgPath: string
  imgDesc: string
  link: string
  title: string
  info: string
}

export const ARTICLE_CONTENT: IArticleItemProps[] = [
  {
    imgPath: '/static/images/social-media/forbes_logo.png',
    imgDesc: 'forbes logo',
    link:
      'https://www.forbes.com/sites/forbesfinancecouncil/2019/04/30/business-checking-accounts-the-front-line-of-an-evolving-industry/#521305c2394f',
    title: 'Business Checking Accounts: The Front Line of an Evolving Industry',
    info: 'Forbes.com | March 30, 2019',
  },
  {
    imgPath: '/static/images/social-media/americanbanker_logo.png',
    imgDesc: 'american banker logo',
    link: 'https://www.americanbanker.com/news/fintechs-tell-small-businesses-theyll-make-the-headache-go-away',
    title: 'Fintechs tell small businesses they’ll ‘make the headache go away’',
    info: 'American Banker | December 2, 2019',
  },
]
export const NORTHONE_PEOPLE: ITeamMember[] = [
  {
    name: 'Eytan',
    fullName: 'Eytan Bensoussan',
    description: 'Co-founder and CEO',
    imageSrc: '/static/images/team-images/team-eytan.png',
    imageDesc: 'Eytan the Co-founder and CEO of NorthOne',
    link: 'Eytan-Bensoussan',
    body:
      'Eytan deeply believes that the success of the small business sector is one of the most important causes of our time. It has the potential to reduce income inequality, empower immigrants, and provide disproportionate leadership opportunities for female and minority businesspeople. Creating a banking service that is as modern as these companies is why Eytan started NorthOne. \n\nPrior to NorthOne, Eytan was a Sr. Engagement Manager for McKinsey & Company. He served many wonderful clients in Financial Services industry, usually on the topic of digital customer experience and financial inclusion. Prior to that, he earned his JD/MBA from McGill University and was called to the New York Bar in 2010.',
    socialMedia: {
      twitter: 'northoneapp',
      linkedIn: 'eytanbensoussan',
    },
    personalInfo: {
      homeTown: 'Montreal, QC',
      inspiration: 'Gary Vaynerchuk',
      book: `Chasing Daylight by Eugene O'Kelly`,
      podcast: 'The Internet History Podcast',
    },
  },
  {
    name: 'Justin',
    fullName: 'Justin Adler',
    description: 'Co-founder and COO',
    imageSrc: '/static/images/team-images/team-justin.png',
    imageDesc: 'Justin the co-founder and COO at NorthOne',
    link: 'Justin-Adler',
    body:
      'Justin leads operations and strategy with a particular focus on the intersection of growth and product at NorthOne. He started NorthOne because he believes in making economic mobility more accessible to today’s entrepreneurs. \n\nPrior to NorthOne Justin was part of the foundational team at Frank & Oak where he helped them grow from zero to hundreds of thousands of customers. Justin also spent time leading product at SourceKnowledge where he created AdTech solutions used by brands like Trivago, SAP, BMO, Remax and MachineZone. His work has been featured in TechCrunch, Fast Company, VentureBeat, AdAge, and at SXSW Interactive.',
    personalInfo: {
      homeTown: 'Montreal, QC',
      inspiration: 'Dieter Rams',
      book: 'Zero To One by Peter Thiel',
      podcast: 'Reply All',
    },
    socialMedia: {
      twitter: 'thejustinadler',
      linkedIn: 'thejustinadler',
    },
  },
]
