import React from 'react'
import { theme, FadeInImage } from '../../shared'
import { Box } from 'rebass'
import { Text } from '@northone/ui'

export const IntegrationsContents = [
  { subtitle: 'Deposit money from', imageName: 'Integrations-Deposit' },
  { subtitle: 'Works with', imageName: 'Integrations-WorksWith' },
]

export const Integrations: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      textAlign: 'center',
      position: ['relative', 'relative', null, null, null],
      top: ['-40px', '0px', '-10px', '-10px', '-10px'],
      paddingY: ['0px', theme.halfVerticalSectionSpacing, '0px', '0px', '0px'],
    }}
  >
    <Text
      tag={'h5'}
      sx={{
        ...theme.fontstyles.eyeBrowText,
      }}
    >
      INTEGRATIONS
    </Text>
    <Box sx={{ marginTop: 30, marginBottom: 60 }}>
      <Text
        tag={['h3Serif', 'h3Serif', 'h3Serif', 'h2']}
        sx={{ color: theme.colors.blackPrimary, fontFamily: theme.fonts.plantinProSemiBold }}
      >{`Connect to the apps your business uses every day`}</Text>
    </Box>
    <IntegrationsIconList />
  </Box>
)

export const IntegrationsIconList: React.FC = () => {
  return (
    <>
      <FadeInImage
        sx={{
          width: ['0px', '0px', '100%', '100%', '100%'],
          display: ['none', 'none', 'block', 'block', 'block'],
        }}
        src={`static/images/homepage/image-cluster.png`}
      />
      <FadeInImage
        sx={{
          width: ['100%', '100%', '0px', '0px', '0px'],
          display: ['block', 'block', 'none', 'none', 'none'],
        }}
        src={`static/images/homepage/mobile/image-cluster-mobile.png`}
      />
    </>
  )
}
