import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import { handleButtonClick, BoxOnBackground } from '../../shared'
import checkDepositAnimation from '../../lottie/check-deposit.json'
import moveMoneyAnimation from '../../lottie/move-money.json'

export const BranchlessBanking: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth)
    })

    return () => {
      window.addEventListener('resize', () => {
        setWindowWidth(window.innerWidth)
      })
    }
  }, [])

  return (
    <BoxOnBackground
      containerSx={{ backgroundPosition: ['center', 'center', '30% 0%'] }}
      noBorderRadius
      withATMPopup
      title="Make deposits from anywhere"
      tagline={windowWidth > 768 ? 'GO BRANCHLESS' : 'BRANCHLESS BANKING'}
      ctaButton={{
        text: 'Find an ATM',
        type: 'copperPrimary',
        onClick: () => handleButtonClick({ url: 'https://www.nyce.net/en/consumers/atm-locator ' }),
        buttonSx: {
          marginTop: 20,
          width: '200px',
        },
      }}
      listItems={[
        {
          imageComponent: <Lottie options={{ animationData: checkDepositAnimation, loop: true, autoplay: true }} />,
          paragraph: 'Deposit checks through the NorthOne mobile app²',
        },
        {
          imageComponent: <Lottie options={{ animationData: moveMoneyAnimation, loop: true, autoplay: true }} />,
          paragraph: "Move cash through one of America's largest networks⁵",
        },
      ]}
      backgroundImageUrl={['/static/images/homepage/branchless-banking-desktop.png']}
      mobileBackgroundImageUrl="/static/images/homepage/branchless-banking-mobile.png"
    />
  )
}
