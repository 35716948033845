import React, { useState } from 'react'
import { theme, TextBlock, Text } from '../../shared'
import { Box, Image } from 'rebass'
import { HomeMaxWidth } from '../../shared/components/home-max-width'
import { CTAButton } from '../../shared/components/v2/CtaButton/ctaButton'
import { fireWebsiteSignupEvent } from '../../utils'

export const Landing: React.FC = () => {
  const [email, setEmail] = useState<string>()
  const [emailCaptured, setEmailCaptured] = useState<boolean>(false)

  const onChangeEmail = (email: string) => {
    if (!emailCaptured) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      window.analytics.track('Email Captured', {
        category: 'Website - Homepage Email Captured',
        action: 'Email field changed',
      })
      setEmailCaptured(true)
    }
    setEmail(email)
  }

  function onSubmit(e: React.FormEvent) {
    e.preventDefault()
    window.open(email ? `https://join.northone.com?email=${email}` : 'https://join.northone.com', '_blank')
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundImage: ['none', 'none', 'url(static/v2/homepage/homepage-desktop.webp)'],
          backgroundColor: [theme.colors.goldTint4, theme.colors.goldTint4, 'none'],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          minHeight: '100%',
        }}
      >
        <HomeMaxWidth
          sx={{
            paddingY: [90, 61, 90, 140, 140],
            paddingBottom: [61, 47, 90, 140, 140],
            minHeight: '100%',
            alignItems: ['center', 'center', 'flex-start'],
          }}
        >
          <TextBlock
            containerSx={{ width: ['100%', '100%', '55%', '55%', '40%'] }}
            // textColor={theme.colors.white}
            title={`Business banking\nmade for America`}
            titleTag={['h2', 'h2', 'h2', 'h2']}
            titleStyle={{
              color: [theme.colors.blackPrimary, theme.colors.blackPrimary, theme.colors.white],
              fontSize: ['36px', '36px', '48px'],
              fontWeight: '800',
              lineHeight: ['40px', '40px', '50px'],
              letterSpacing: ['-0.01em !important'],
            }}
            paragraphTag="body-large"
            paragraphColor={theme.colors.blackPrimary}
            paragraphJSX={
              <>
                <Text
                  tag={'p'}
                  sx={{
                    color: [theme.colors.blackPrimary, theme.colors.blackPrimary, theme.colors.white],
                    textAlign: ['center', 'center', 'left'],
                    fontFamily: theme.fonts.grtskPetaLight,
                    fontSize: '22px',
                    marginX: ['20px', '0px', '0px', '0px', '0px'],
                    lineHeight: '30px',
                    letterSpacing: '-0.01em',
                  }}
                >
                  {'NorthOne makes the hardest parts of running your business easy.'}
                </Text>

                <Text
                  tag={'p'}
                  sx={{
                    color: [theme.colors.blackPrimary, theme.colors.blackPrimary, theme.colors.white],
                    margin: ['46px 0px 5px 0px', '29px 0px 22px 0px'],
                    textAlign: ['center', 'center', 'left'],
                    fontFamily: theme.fonts.grtskPetaLight,
                    fontSize: '22px',
                    letterSpacing: '-0.18px',
                  }}
                >
                  Open an account in minutes.¹
                </Text>
              </>
            }
            subContent={
              <>
                <Box
                  as={'form'}
                  onSubmit={onSubmit}
                  sx={{
                    display: 'flex',
                    flexDirection: ['column', 'column', 'row'],
                    alignItems: 'center',
                    marginTop: '27px',
                    height: ['auto', 'auto', '44px'],
                  }}
                >
                  <input
                    type="email"
                    value={email}
                    placeholder="Enter your email"
                    onChange={e => onChangeEmail(e.target.value)}
                    style={{
                      fontFamily: theme.fonts.grtskPetaLight,
                      color: theme.colors.blackTint1,
                      letterSpacing: '-0.01em',
                      borderRadius: 0,
                      height: '44px',
                    }}
                    className="hero-input-field"
                  />{' '}
                  <CTAButton
                    text={'Get Started'}
                    type={'copperPrimary'}
                    linkStyle={{ width: '100%', height: '44px' }}
                    buttonSx={{ height: '100%' }}
                    url={email ? `https://join.northone.com?email=${email}` : 'https://join.northone.com'}
                    onClick={fireWebsiteSignupEvent}
                  />
                </Box>
                <Text
                  tag={'p'}
                  sx={{
                    marginTop: 2,
                    textAlign: ['center', 'center', 'left'],
                    width: ['100%', '100%', '56%'],
                    ...theme.fontstyles.legalDisclaimers,
                    color: [theme.colors.blackPrimary, theme.colors.blackPrimary, theme.colors.white],
                    paddingX: [35, 55, 0, 0, 0],
                  }}
                >
                  {
                    'NorthOne is a financial technology company, not a bank. Banking services provided by The Bancorp Bank, N.A., Member FDIC.'
                  }
                </Text>
              </>
            }
          />
        </HomeMaxWidth>
      </Box>
      <Image
        sx={{ display: ['block', 'block', 'none'], minWidth: '100%', position: 'relative' }}
        src="static/v2/homepage/homepage-mobile.webp"
        alt="homepage mobile"
      />
    </>
  )
}
