export const socialLinks = [
  {
    img: '/static/images/footer/facebook-black.svg',
    href: 'https://www.facebook.com/northoneapp',
    alt: 'facebook logo',
  },
  {
    img: '/static/images/footer/linkedin-black.svg',
    href: 'https://www.linkedin.com/company/northone/',
    alt: 'linkedin logo',
  },
  {
    img: '/static/images/footer/instagram-black.svg',
    href: 'https://www.instagram.com/northone.app/',
    alt: 'instagram logo',
  },
  {
    img: '/static/images/footer/twitter-black.svg',
    href: 'https://twitter.com/NorthOneApp',
    alt: 'twitter logo',
  },
  {
    img: '/static/images/footer/youtube-black.svg',
    href: 'https://www.youtube.com/channel/UC0r1Fb_8moZsKIo7cOBRBOg',
    alt: 'youtube logo',
  },
]

export const footerItems = {
  aboutUs: {
    header: 'About Us',
    links: [
      {
        text: 'About NorthOne',
        href: '/about',
      },
      {
        text: 'Our Team',
        href: '/about/team',
      },
      {
        text: 'Careers',
        href: '/about/careers',
      },
      {
        text: 'Contact Us',
        href: '/contact-us',
      },
      {
        text: 'Press',
        href: 'mailto:press@northone.com',
      },
    ],
  },
  resources: {
    header: 'Resources',
    links: [
      {
        text: 'Blog',
        href: '/blog',
        isBlank: true,
      },
      {
        text: 'FAQ',
        href: 'https://help.northone.com/en/',
      },
    ],
  },
  security: {
    header: 'Security, Privacy & Legal',
    links: [
      {
        text: 'Security',
        href: '/security',
      },
      {
        text: 'Terms of Use',
        href: '/terms-of-use',
      },
      {
        text: 'NorthOne Privacy Policy',
        href: '/privacy-policy',
      },
      {
        text: 'Bancorp Privacy Policy',
        href: '/privacy-policy-bc',
      },
      {
        text: 'Deposit Account Agreement',
        href: '/account-agreement',
      },
      {
        text: 'Agreement to Receive Electronic Communications',
        href: '/esign',
      },
    ],
  },
  connect: {
    header: 'Connect With Us',
    links: [
      {
        text: 'Partners',
        href: '/partners',
      },
      {
        text: 'Support',
        href: 'mailto:support@northone.com',
      },

      {
        text: 'Apply for an account',
        href: 'https://join.northone.com',
      },
    ],
  },
  help: {
    header: 'Need Help?⁶',
    links: [
      { text: 'support@northone.com', href: 'mailto:support@northone.com' },
      { text: '+1 (332) 205-9253', href: 'tel:+13322059253' },
    ],
  },
  offices: {
    header: 'Addresses',
    links: [
      { href: 'https://goo.gl/maps/DqkbbSKbfkDCTsiJA', text: `18 West 18th Street\nNew York, NY 10011` },
      { href: 'https://goo.gl/maps/fcUx2MbqMXP14o168', text: `1050 King St W\nToronto, ON M6K 0C7` },
    ],
  },
}
