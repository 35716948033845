import React from 'react'
import { theme, NewImage, StandardPadding, Text } from '../../../shared'
import { Box } from 'rebass'
import { getTextWithLineBreaks } from '../../../helper'
import { FEATURE_LIST } from './scroll-list'
import { TextWithHighlight } from '@northone/ui'

interface ITextBlockProps {
  title: string
  paragraph: string
}

const TextBlock = ({ title, paragraph }: ITextBlockProps) => {
  return (
    <Box>
      <Text
        sx={{
          ...theme.fontStyles[2],
          fontWeight: 'bold',
          color: theme.colors.white,
          paddingBottom: theme.verticalElementSpacing,
        }}
      >
        {title}
      </Text>
      <Text
        sx={{
          ...theme.fontStyles[1],
          color: theme.colors.white,
          fontWeight: '100',
          paddingBottom: '35px',
        }}
      >
        {getTextWithLineBreaks(paragraph)}
      </Text>
    </Box>
  )
}

export const FeatureScrollMobile = (): JSX.Element => {
  return (
    <StandardPadding
      sx={{
        display: ['flex', 'flex', 'none'],
        flexDirection: 'column',
        alignItems: 'center',
        bg: theme.colors.gold100,
      }}
    >
      <Box
        sx={{
          width: '100vw',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            paddingY: '70px',
          }}
        >
          {FEATURE_LIST.map((item, index) => (
            <Box key={index} sx={{ paddingY: theme.verticalElementSpacing }}>
              {index == 0 || index == 4 ? (
                <Box>
                  <TextWithHighlight
                    tag={'h2'}
                    centered
                    sx={{
                      color: theme.colors.white,
                    }}
                  >
                    {item.header}
                  </TextWithHighlight>
                </Box>
              ) : (
                ''
              )}
              <Box sx={{ paddingY: '20px' }}>
                <NewImage src={item.imageSrc} alt={item.imageDesc} customWidth={['250px', '330px']} />
              </Box>
              <TextBlock title={item.title} paragraph={item.description} />
            </Box>
          ))}
        </Box>
      </Box>
    </StandardPadding>
  )
}
