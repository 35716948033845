import React from 'react'
import { BaseLayout, TextBlock, FlipCountUp, theme } from '../../shared'
import { ISBAProps } from './types'
import { Box } from 'rebass'
import { Text } from '@northone/ui'

interface ISBACounterProps {
  SBAFundingTotal: number
}

const SBA_FUNDING_START = 12982039 // Random number to reduce the render distance of FlipCountUp
const SBA_FUNDING_TOTAL = 130880846 // Desired end number to display on FlipCountUp if data from prismic is unavailable

export const SBALanding = ({ PPPAmount }: ISBAProps) => {
  return (
    <BaseLayout
      marginTop={theme.halfVerticalSectionSpacing}
      centeredComponent={
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextBlock
            centered
            title={'The Paycheck Protection Program with \nNorthOne and Cross River Bank'}
            titleTag="h2"
            titleStyle={{ fontSize: ['36px', '36px', '48px'], lineHeight: ['40px', '40px', '54px'] }}
          />

          <SBACounter SBAFundingTotal={PPPAmount || SBA_FUNDING_TOTAL} />
          <TextBlock
            paragraphTag={'body'}
            paragraphStyle={{ width: [327, 327, 600], textAlign: 'center', alignSelf: 'center' }}
            paragraph="The Paycheck Protection Program officially ended on May 31, 2021. While we are no longer accepting PPP applications, you can apply for a NorthOne Account for a fast and simple business banking experience."
            centered
            ctaButton={{
              text: 'Apply for an account',
              type: 'blackPrimary',
              url: 'https://join.northone.com/',
              buttonSx: {
                width: [285, 285, 235],
              },
            }}
          />
          <Text
            tag="body-x-small"
            sx={{ width: [327, 327, 600], textAlign: 'center', alignSelf: 'center', marginTop: 20 }}
          >{`The Bancorp Bank, N.A. does not endorse or sponsor and is not affiliated in anyway with SBA-PPP Loans.`}</Text>
        </Box>
      }
      boxSx={{
        paddingX: 60,
      }}
    />
  )
}

const SBACounter = ({ SBAFundingTotal }: ISBACounterProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
    <Text tag="body">NorthOne helped facilitate</Text>
    <FlipCountUp
      sx={{ width: [325, 325, 480], marginY: '8px' }}
      panelSx={{
        width: ['30px', '30px', '48px'],
        height: ['40px', '50px', '73px'],
        fontSize: ['25px', '25px', '50px'],
        borderRadius: ['8px', '10px', '14px'],
      }}
      prefix="$"
      start={SBA_FUNDING_START}
      end={SBAFundingTotal}
    />
    <Text tag="body">in PPP loans for small business owners</Text>
  </Box>
)
