import React from 'react'
import { BaseLayout, TextBlock, NewImage, theme } from '../../../shared'
import { fireWebsiteSignupEvent } from '../../../utils'
import { ONBOARDING_URL } from '../../../utils/urls'

export const FeaturesLanding: React.SFC<{}> = () => (
  <BaseLayout
    bodyComponent={
      <TextBlock
        titleTag="h2"
        title={'A Deposit Account that __saves__ you time and money'}
        paragraph={`NorthOne was built to make managing money easy so you can focus on running your business.`}
        paragraphStyle={{ ...theme.fontStyles[3] }}
        paragraphColor={theme.colors.ui1}
        ctaButton={{
          text: 'Apply for an account',
          type: 'blackPrimary',
          url: ONBOARDING_URL,
          onClick: fireWebsiteSignupEvent,
          buttonSx: {
            width: [285, 285, 235],
          },
        }}
      />
    }
    imageComponent={
      <NewImage
        alt="NorthOne MasterCard card with iPhone displaying move money options"
        src="/static/images/product/features/about-insights.png"
      />
    }
  />
)
