import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { theme } from '../../../shared/styles'
import { Text } from '@northone/ui'
import { Box, Image } from 'rebass'
import { motion, useAnimation, AnimatePresence } from 'framer-motion'

interface ICarouselPaginationProps {
  carouselLength: number
  selectedSlideIndex: number
  setSelectedSlideIndex: Dispatch<SetStateAction<number>>
  duration: number
}

export const CarouselPagination = ({
  carouselLength,
  selectedSlideIndex,
  setSelectedSlideIndex,
  duration,
}: ICarouselPaginationProps) => {
  const carouselLastIndex = carouselLength - 1
  const controls = useAnimation()

  useEffect(() => {
    controls.set({ width: '0%' })
    controls.start({ width: '100%' }).then(() => {
      controls.set({ width: '0%' })
      setTimeout(() => setSelectedSlideIndex((selectedSlideIndex + 1) % carouselLength), 0)
    })
  }, [selectedSlideIndex])
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 31, width: '100%' }}>
      {[...Array(carouselLength)].map((_, index) => (
        <Box
          key={index}
          onClick={() => setSelectedSlideIndex(index)}
          sx={{
            width: 47,
            height: '4px',
            marginRight: index !== carouselLastIndex ? [24, 28] : 0,
            cursor: 'pointer',
            bg: theme.colors.gold30,
          }}
        >
          <motion.div
            style={{
              width: '0%',
              height: '100%',
              backgroundColor: index === selectedSlideIndex ? theme.colors.copperDark : theme.colors.gold30,
            }}
            transition={{ duration: duration / 1000 }}
            animate={controls}
          />
        </Box>
      ))}
    </Box>
  )
}
interface ICarouselImageWithBannerProps {
  image: string
  imageDesc: string
  icon: string
  text: string
  isSelected: boolean
  hiddenFor?: 'mobile' | 'desktop'
  iconWidth?: number[]
}

export const CarouselImageWithBanner = ({
  hiddenFor,
  isSelected = false,
  text,
  image,
  imageDesc,
}: ICarouselImageWithBannerProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: hiddenFor === 'mobile' ? ['none', 'none', 'block'] : ['block', 'block', 'none'],
      }}
    >
      <AnimatePresence initial={false}>
        {isSelected && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Image
              sx={{ width: ['100%', '100%', 573, 759], height: 'auto', position: 'absolute' }}
              src={image}
              alt={imageDesc}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isSelected && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.8 }}
            exit={{ opacity: 0 }}
          >
            <Box
              sx={{
                position: ['absolute'],
                width: ['100%', '100%', '100%', '58%', '58%'],
                height: '100%',
                paddingLeft: [0, 0, 20, 50, 50],
                paddingBottom: [15, 15, 15, 50, 50],
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: ['center', 'center', 'flex-start', 'flex-end', 'flex-end'],
                justifyContent: 'flex-end',
              }}
            >
              <Box sx={{ width: ['70%', '70%', '50%', '100%', '100%'] }}>
                <Text
                  tag={['body-bold', 'body-large-bold', 'body-bold', 'body-bold', 'body-bold']}
                  textColor={theme.colors.white}
                  sx={{
                    fontFamily: theme.fonts.plantinProSemiBold,
                    fontSize: ['24px', '24px', '24px', '38px', '36px'],
                    lineHeight: ['28px', '28px', '28px', '40px', '40px'],
                    letterSpacing: ['-0.16px', '-0.16px'],
                    textAlign: ['center', 'center', 'left'],
                  }}
                >
                  {text}
                </Text>
              </Box>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  )
}
