import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import Head from 'next/head'
import { Box } from 'rebass'
import { Breadcrumbs, theme } from '../../shared'
import { TBreadcrumb } from '../../utils'
import { Footer } from '../footer'
import { Navbar } from '../navbar'
// import { WindowWidthHelper } from './window-width-helper'
// 10.0.27 emotion-theming
import { Theme } from 'styled-system'
import DisclosureBanner from '../../pages/disclosure-banner'
import { unauthenticatedUserIntercomSettings } from '../../utils/intercom'

interface IProps {
  headKey: string
  title: string
  breadcrumbs?: TBreadcrumb[]
  children: JSX.Element | JSX.Element[]
  metaDescription?: string
  customTheme?: Partial<Theme>
  customFooterBody?: string | JSX.Element
}

const DEBUG = false

export const Base = ({
  title,
  breadcrumbs,
  children,
  headKey,
  metaDescription,
  // customTheme,
  customFooterBody,
}: IProps): JSX.Element => {
  const bootIntercomInterval = setInterval(() => {
    if (typeof window !== 'undefined' && window.Intercom) {
      window.Intercom('boot', {
        ...unauthenticatedUserIntercomSettings,
      })
      clearInterval(bootIntercomInterval)
    }
  }, 100)

  return (
    <ThemeProvider theme={theme}>
      {/* {process.env.NODE_ENV === 'development' && <WindowWidthHelper />} */}
      <div className={DEBUG ? 'debug debug-grid' : ''} style={{ width: '100%' }}>
        <Head key={headKey}>
          {/* Set the screen to 90% zoom */}
          <meta name="viewport" content="width=device-width, initial-scale=0.9" />
          <meta charSet="utf-8" />
          <meta name="description" content={metaDescription} />

          <title>{title}</title>
          <link rel="icon" href="https://northone-assets.s3.us-east-2.amazonaws.com/favicon.png" hrefLang="en" />
          <link rel="stylesheet" href="https://unpkg.com/tachyons@4.10.0/css/tachyons.min.css" hrefLang="en" />
        </Head>
        <Navbar />
        <Box paddingTop={breadcrumbs ? theme.navbar.breadcrumbPageOffset : theme.navbar.mainPageOffset}>
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          {children}
        </Box>
        <Footer customBody={customFooterBody} />
        <style jsx global>
          {`
            :global(body) {
              margin: 0;
              font-family: 'CerebriSans';
              font-weight: 400;
              color: #24343e;
              -webkit-font-smoothing: antialiased;
              font-display: swap;
            }

            @font-face {
              font-family: 'CerebriSans';
              src: url('/static/fonts/CerebriSansRegular.otf');
              font-weight: 400;
              font-display: swap;
            }

            @font-face {
              font-family: 'CerebriSans';
              src: url('/static/fonts/CerebriSansSemiBold.otf');
              font-weight: 600;
              font-display: swap;
            }

            @font-face {
              font-family: 'Graphik';
              src: url('/static/fonts/Graphik-Light-Web.woff') format('woff');
              font-style: normal;
              font-weight: 300;
              font-display: swap;
            }

            @font-face {
              font-family: 'Graphik';
              src: url('/static/fonts/Graphik-Medium-Web.woff') format('woff');
              font-style: normal;
              font-weight: 500;
              font-display: swap;
            }

            @font-face {
              font-family: 'Graphik';
              src: url('/static/fonts/Graphik-Regular-Web.woff') format('woff');
              font-style: normal;
              font-weight: normal;
              font-display: swap;
            }

            @font-face {
              font-family: 'GrtskPeta';
              src: url('/static/fonts/GrtskPeta-Regular.ttf');
              font-display: swap;
            }

            @font-face {
              font-family: 'GrtskPetaLight';
              src: url('/static/fonts/GrtskPeta-Light.ttf');
              font-display: swap;
            }

            @font-face {
              font-family: 'GrtskPetaSemi';
              src: url('/static/fonts/GrtskPeta-Semibold.ttf');
              font-display: swap;
            }

            @font-face {
              font-family: 'GrtskExa';
              src: url('/static/fonts/GrtskExa-Regular.ttf');
              font-display: swap;
            }

            @font-face {
              font-family: 'GrtskExaSemi';
              src: url('/static/fonts/GrtskExa-Semibold.ttf');
              font-display: swap;
            }

            @font-face {
              font-family: 'Plantin-MT-Pro-Light';
              src: url('/static/fonts/Plantin-MT-Pro-Light.woff') format('woff');
              font-style: normal;
              font-weight: 300;
              font-display: swap;
            }

            @font-face {
              font-family: 'Plantin-MT-Pro-Semi-Bold';
              src: url('/static/fonts/Plantin-MT-Pro-Semi-Bold.ttf');
              font-display: swap;
            }

            a {
              text-decoration: none;
            }
          `}
        </style>
      </div>
      <DisclosureBanner />
    </ThemeProvider>
  )
}
