import { theme } from '@northone/ui'

interface IIntercomSettings {
  alignment: string
  horizontalPadding: number
  verticalPadding: number
  backgroundColor: string
  actionColor: string
}

declare global {
  /* eslint-disable-next-line @typescript-eslint/interface-name-prefix */
  interface Window {
    Intercom(update: string, params?: IIntercomSettings): void
    intercomSettings: IIntercomSettings
  }
}

export const unauthenticatedUserIntercomSettings = {
  alignment: 'right',
  horizontalPadding: 20,
  verticalPadding: 20,
  backgroundColor: theme.colors.gold60,
  actionColor: theme.colors.gold80,
}
