import React, { useEffect, useState } from 'react'
import { Box } from 'rebass'
import Link from 'next/link'
import { Text } from '@northone/ui'
import { theme } from '../../../shared'

import { generatePathName } from '../../../helper'
import { IActiveLink } from '../dynamic-navbar/dynamic-navbar'

const BODY_LARGE_TEXT_STROKE = `0.5px ${theme.colors.blackPrimary}`

interface ISubPathExpansionNavLinkProps {
  menuItem: string
  key: number
  link: IActiveLink
}

export const SubPathExpansionNavLink = ({ menuItem, key, link }: ISubPathExpansionNavLinkProps) => {
  const [isCurrentSubPath, setIsCurrentSubPath] = useState<boolean>()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsCurrentSubPath(generatePathName(menuItem) === window.location.pathname.split('/')[2])
    }
  }, [])

  const [hoverTextStroke, setHoverTextStroke] = useState<string>()
  const isBlogMenuItem = menuItem === 'Blog'
  const handleOnMouseEnter = () => {
    setHoverTextStroke(BODY_LARGE_TEXT_STROKE)
  }
  return (
    <Box sx={{ marginTop: key === 0 ? 0 : '5px', cursor: 'pointer', display: 'block' }}>
      <Link
        href={isBlogMenuItem ? '/blog' : `/${generatePathName(link.label)}/[menu]`}
        as={isBlogMenuItem ? '/blog' : `/${generatePathName(link.label)}/${generatePathName(menuItem)}`}
      >
        {isCurrentSubPath ? (
          <a style={{ textDecoration: 'none' }} target={isBlogMenuItem ? '_blank' : undefined}>
            <Text
              tag="body-large"
              textColor={theme.colors.blackPrimary}
              style={{
                WebkitTextStroke: BODY_LARGE_TEXT_STROKE,
              }}
              sx={{
                cursor: 'pointer',
                lineHeight: '22px',
                fontFamily: theme.fonts.grtskPeta,
              }}
            >
              {menuItem}
            </Text>
          </a>
        ) : (
          <a style={{ textDecoration: 'none' }} target={isBlogMenuItem ? '_blank' : undefined}>
            <Text
              tag="body-large"
              textColor={theme.colors.blackPrimary}
              sx={{
                WebkitTextStroke: hoverTextStroke,
                cursor: 'pointer',
                ':hover': { color: theme.colors.blackPrimary },
                lineHeight: '22px',
                fontFamily: theme.fonts.grtskPeta,
              }}
              onMouseEnter={() => handleOnMouseEnter()}
              onMouseLeave={() => setHoverTextStroke(undefined)}
            >
              {menuItem}
            </Text>
          </a>
        )}
      </Link>
    </Box>
  )
}
