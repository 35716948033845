import React from 'react'
import { FAQ } from '../../components'
// import { theme } from '../../shared'

const SECURITY_FAQ_CONTENT = [
  {
    title: 'Is my account insured?',
    text: (
      <p>
        All accounts opened with NorthOne are FDIC insured. Banking services are provided by The Bancorp Bank, N.A.,
        Member FDIC. That means your deposits are federally protected for up to $250,000 against bank failure or theft.
      </p>
    ),
  },
  {
    title: 'What is The Bancorp Bank, N.A.?',
    text: (
      <p>
        NorthOne is partnered with The Bancorp Bank, N.A., a federally-chartered bank and member of the FDIC. The
        Bancorp Bank, N.A. has built out powerful API-driven technology to support quick digital account opening and
        other self-serve features that empower small business owners.
      </p>
    ),
    href: 'https://radiusbank.com/',
  },
  {
    title: 'What do I do if my debit card was lost or stolen?',
    text: (
      <p>
        All you need to do is contact our support team through the NorthOne app. New cards are usually delivered within
        5 business days.
      </p>
    ),
  },
  {
    title: 'Is my personal information safe?',
    text: (
      <p>
        Yes. We take privacy very seriously. We never store or maintain any sensitive personal information, and will
        only communicate with you through secure channels.
      </p>
    ),
  },
  {
    title: 'Is mobile banking safe?',
    text: (
      <p>
        Yes. We protect your account with multi-factor authentication, constantly monitor transactions for any
        suspicious activities, and will notify you immediately if there’s anything to be concerned with. Plus, your
        account is FDIC-insured. Banking services are provided by The Bancorp Bank, N.A., Member FDIC.
      </p>
    ),
  },
]

export const SecurityFAQ = () => (
  <FAQ
    href="https://help.northone.com/en/?q=security"
    header="__Security__ FAQ"
    content={SECURITY_FAQ_CONTENT}
    // buttonText={'More FAQs'}
    hasBrownBackground
    customHorizontalPadding={'0px'}
    textHeroPaddingBottom={'50px'}
    // titleHighlightColor={`${theme.colors.white}20`}
  />
)
