import React from 'react'
import { TextBlockList, BaseLayout, TextBlock, theme, NewImage } from '../../shared'
import { Box, Link } from 'rebass'
import { Text } from '@northone/ui'

const SBA_DETAILS = [
  {
    imageSrc: '/static/images/sba-loan-application/icon-deposit.svg',
    paragraph: 'Make deposits from anywhere with mobile check depositing and ACH transfers.',
    imgDesc: 'Date Icon',
  },
  {
    imageSrc: '/static/images/sba-loan-application/icon-envelopes.svg',
    paragraph: 'Create Envelopes to plan and budget for taxes, payroll, rent, and anything else automatically.',
    imgDesc: 'Tax Returns Icon',
  },
  {
    imageSrc: '/static/images/sba-loan-application/icon-connect.svg',
    paragraph: 'Connect your account to apps like Stripe, Shopify, and Quickbooks seamlessly.',
    imgDesc: 'Routing Account Icon',
  },
  {
    imageSrc: '/static/images/sba-loan-application/icon-apply.svg',
    paragraph: 'Apply directly through the link provided on this page.',
    imgDesc: 'Apply Icon',
  },
]

export const SBADetails = (): JSX.Element => (
  <BaseLayout
    reverseMobile
    bodyComponentWidth={['100%', '100%', '300px', '330px', '500px']}
    marginTop="0px"
    containerSx={{ paddingY: '30px' }}
    color={theme.colors.gold30}
    imageComponentWidth={['auto', 'auto', '50%']}
    imageComponent={
      <Box>
        <TextBlock
          title={`Apply for a NorthOne Account`}
          titleTag="h2"
          textColor={theme.colors.ui1}
          // titleHighlightColor={theme.highlightColors.onGold80}
        />
        {SBA_DETAILS.map((item, index) => (
          <TextBlockList
            customItemSpacing={'10px'}
            textColor={theme.colors.ui1}
            key={index}
            imageSrc={item.imageSrc}
            imageAlt={item.imgDesc}
            paragraph={item.paragraph}
            paragraphElement={
              index === 3 ? (
                <Text tag={'body-large'} color={theme.colors.ui1}>
                  Apply directly for a NorthOne account{' '}
                  <Link
                    target="_blank"
                    style={{ textDecoration: 'underline', color: theme.colors.ui1 }}
                    href="https://join.northone.com/"
                  >
                    here
                  </Link>
                  {'.'}
                </Text>
              ) : (
                undefined
              )
            }
          />
        ))}
      </Box>
    }
    bodyComponent={
      <NewImage
        src="/static/images/sba-loan-application/sba-app-screenshot.png"
        alt="Phone displaying home page alongside credit card"
      />
    }
  />
)
