import React from 'react'
import { theme, handleButtonClick } from '../../shared'
import { Box, Image } from 'rebass'
import { Text as N1Text } from '@northone/ui'
import { CTAButton } from '../../shared/components/v2/CtaButton/ctaButton'

interface ICenterContentProps {
  isDesktopScreen: boolean
}

export const SubAccounts: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: ['column', 'column', 'column', 'column', 'row'],
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.colors.copperTint4,
      height: ['1050px', '100%', '100%', '100%', '1400px'],
      marginBottom: ['120px', '20px', '120px'],
      paddingBottom: ['300px', '100px', '100px', '100px', '320px'],
      paddingTop: ['0px', '50px', '50px', '0px', '0px'],
    }}
  >
    <CenterContent isDesktopScreen={false} />
    <DesktopImageCluster />
    <Box
      sx={{
        display: ['none', 'none', 'block', 'block', 'none'],
        position: 'relative',
        top: '50px',
      }}
    >
      <Image
        height={'403px'}
        width={'auto'}
        src="/static/v2/homepage/sub-account-tablet.png"
        alt="sub account tablet"
      />
    </Box>
    <Box
      sx={{
        display: ['block', 'block', 'none', 'none', 'none'],
        position: 'relative',
        top: '40px',
      }}
    >
      <Image
        maxHeight={'612px'}
        width={'auto'}
        height={'auto'}
        src="/static/v2/homepage/sub-account-mobile.png"
        alt="sub account mobile"
      />
    </Box>
  </Box>
)

const DesktopImageCluster: React.FC = () => (
  <Box
    sx={{
      maxWidth: '1440px',
      height: '100%',
      width: '100%',
      position: 'relative',
      gridTemplateColumns: 'repeat(20, 1fr)',
      gridTemplateRows: 'repeat(45, 1fr)',
      display: ['none', 'none', 'none', 'none', 'grid'],
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        gridRowStart: 8,
        gridColumnStart: 0,
      }}
    >
      <Image height={'509px'} width={'auto'} src="/static/v2/homepage/sub-account-a.png" alt="sub account a" />
    </Box>
    <Box
      sx={{
        position: 'absolute',
        gridRowStart: 5,
        gridColumnStart: 9,
        zIndex: 1,
      }}
    >
      <Image height={'390px'} width={'auto'} src="/static/v2/homepage/sub-account-b.png" alt="sub account b" />
    </Box>
    <Box
      sx={{
        position: 'absolute',
        gridRowStart: 20,
        right: 0,
        zIndex: 0,
      }}
    >
      <Image height={'463px'} width={'auto'} src="/static/v2/homepage/sub-account-c2.png" alt="sub account c2" />
    </Box>
    <Box
      sx={{
        position: 'absolute',
        gridRowStart: 43,
        gridColumnStart: 13,
      }}
    >
      <Image height={'234px'} width={'auto'} src="/static/v2/homepage/sub-account-d.png" alt="sub account d" />
    </Box>
    <Box
      sx={{
        position: 'absolute',
        gridRowStart: 32,
        gridColumnStart: 2,
      }}
    >
      <Image height={'524px'} width={'auto'} src="/static/v2/homepage/sub-account-e2.png" alt="sub account e2" />
    </Box>
    <CenterContent isDesktopScreen />
  </Box>
)
const CenterContent = ({ isDesktopScreen }: ICenterContentProps) => (
  <Box
    sx={{
      display: isDesktopScreen ? ['none', 'none', 'none', 'none', 'flex'] : ['flex', 'flex', 'flex', 'flex', 'none'],
      flexDirection: 'column',
      maxWidth: '700px',
      justifyContent: 'space-around',
      textAlign: 'center',
      alignItems: 'center',
      justifySelf: 'center',
      height: ['300px', '285px', '280px', '280px', 'auto'],
      width: ['100%', '100%', '100%', '100%', '100%'],
      padding: ['0px 25px', '0px 30px', '0px 20px', '20px 20px 0px 20px', '40px 20px'],
      marginTop: ['320px', '40px', '40px', '40px', '15px'],
      position: ['static', 'static', 'static', 'static', 'absolute'],
      gridRowStart: 22,
      gridColumnStart: 6,
      gridColumnEnd: 16,
    }}
  >
    <N1Text tag={'h5'} sx={{ marginBottom: '20px', ...theme.fontstyles.eyeBrowText }}>
      ENVELOPES
    </N1Text>
    <N1Text
      tag={isDesktopScreen ? 'h2' : 'h3Serif'}
      sx={{ color: theme.colors.blackPrimary, fontFamily: theme.fonts.plantinProSemiBold }}
    >{`Plan and budget for what matters most`}</N1Text>
    <N1Text
      tag={'body-large'}
      fontFamily={theme.fonts.grtskPetaLight}
      fontSize={isDesktopScreen ? `22px` : `16px`}
      lineHeight={isDesktopScreen ? `30px` : `22px`}
      letterSpacing={'-0.16px'}
      textColor={theme.colors.blackPrimary}
      marginTop={20}
      width={'90%'}
    >{`Create Envelopes to automatically budget for taxes, payroll, rent, and other business expenses.`}</N1Text>
    <CTAButton
      text={'Learn More'}
      type={'copperPrimary'}
      onClick={() => handleButtonClick({ url: '/product/features' })}
      buttonSx={{
        marginTop: ['20px', '20px', '40px', '40px', '40px'],
      }}
    />
    <N1Text
      sx={{
        width: ['90%', '90%', '50%'],
        marginTop: 20,
        fontFamily: theme.fonts.grtskExa,
        color: theme.colors.blackPrimary,
      }}
      tag={'body-x-small'}
    >
      {
        'NorthOne Envelopes let you designate a portion of your balance to view on the mobile app or website to help you stay organized.'
      }
    </N1Text>
  </Box>
)
