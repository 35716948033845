import React from 'react'
import { Box } from 'rebass'
import { motion } from 'framer-motion'

import { NAVBAR_LINKS } from '../navbar'
import { ExpansionNavLink } from './link'

export const AnimatedExpansionNavLinkContainer: React.FC = () => {
  return (
    <React.Fragment>
      {NAVBAR_LINKS.map((link, idx) => {
        const { label } = link
        return (
          <motion.div
            layout
            key={label}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.5,
                delay: (idx + 1) * 0.15,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.5,
                delay: 0.4 / (idx + 1),
              },
            }}
          >
            <Box
              sx={{
                marginTop: idx === 0 ? 0 : 24,
              }}
            >
              <ExpansionNavLink key={label} link={link} />
            </Box>
          </motion.div>
        )
      })}
    </React.Fragment>
  )
}
