import React from 'react'

import { FlexColumnLayout } from '@northone/ui'
import { DynamicNavBar } from './dynamic-navbar/dynamic-navbar'
import { StaticNavbar } from './static-navbar'
import { theme } from '../../shared'

export const NAVBAR_LINKS = [
  { label: 'Our Customers', menu: ['Small Business', 'Freelancer', 'Startup'] },
  { label: 'Product', menu: ['Features', 'Pricing', 'Integrations', 'Getting Started'] },
  { label: 'About Us', menu: ['Our Team', 'Blog'] },
]

export const Navbar: React.FC = () => {
  return (
    <FlexColumnLayout
      sx={{
        width: '100%',
        position: 'fixed',
        zIndex: 10,
        top: 0,
        backgroundColor: theme.colors.white,
      }}
    >
      <StaticNavbar />
      <DynamicNavBar />
    </FlexColumnLayout>
  )
}

export const NAVBAR_DIMENSIONS = {
  primary: {
    height: 88,
    heightMobile: 63,
    horizontalPaddingLeftDesktop: 61,
    horizontalPaddingRightDesktop: 68,
    horizontalPaddingTablet: 24,
    horizontalPaddingMobile: 27,
  },
  secondary: {
    height: 37,
    heightMobile: 24,
    horizontalPaddingMobile: 22,
  },
  tertiary: {
    height: 45,
  },
}
