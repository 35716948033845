import React, { useEffect, useState } from 'react'
import { theme, FadeInImage } from '../../shared'
import { Box } from 'rebass'
import { HomeMaxWidth } from '../../shared/components/home-max-width'
import { Text, FlexRowLayout } from '@northone/ui'
import { TextWithHighlight } from '../../shared/components/text-with-highlight'
import { CTAButton } from '../../shared/components/v2/CtaButton/ctaButton'

interface IPricingItemProps {
  text: string
}

const rotatingText = [
  'out of network ATM withdrawals',
  'too many or too few transactions',
  'processing cash transfers',
  'ACH payments',
  'debit card purchases',
  'replacement debit cards',
]

export const Pricing = (): JSX.Element => {
  const textAlign = ['center', 'center', 'left']
  const [textIndex, setTextIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((textIndex + 1) % rotatingText.length)
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [textIndex])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        bg: `${theme.colors.goldTint4}`,
        paddingY: [
          theme.halfVerticalSectionSpacing,
          theme.halfVerticalSectionSpacing,
          theme.verticalSectionSpacing,
          theme.verticalSectionSpacing,
          theme.verticalSectionSpacing,
        ],
      }}
    >
      <HomeMaxWidth
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column', 'row'],
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FadeInImage
          // src="https://northone-assets.s3.us-east-2.amazonaws.com/website/homepage/pricing-02.png"
          src="/static/images/pricing/pricing-02-new.png"
          sx={{
            height: ['auto', 'auto', '420px', '420px', '560px'],
            overflow: 'hidden',
          }}
          alt="pricing"
        />

        <Box
          sx={{
            width: ['100%', '100%', '40%'],
            height: [null, null, '500px', '500px', '560px'],
            display: 'flex',
            flexDirection: 'column',
            alignItems: ['center', 'center', 'flex-start'],
          }}
        >
          <Text
            sx={{
              ...theme.fontstyles.eyeBrowText,
              marginBottom: 20,
              marginTop: [50, 50, 0, 0, 0],
              textAlign,
            }}
            textColor={theme.colors.copperPrimary}
            tag="h5"
          >
            Pricing
          </Text>
          <TextWithHighlight
            centered={[true, true, false]}
            highlightColor={'transparent'}
            tag={['h3Serif', 'h3Serif', 'h3Serif', 'h2']}
            textColor={theme.colors.blackPrimary}
            sx={{ lineHeight: ['28px', '28px', '40px', '40px', '40px'] }}
            superScript="7"
          >
            {`Open a NorthOne Account for one __low__ monthly fee`}
          </TextWithHighlight>
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'row'],
              alignItems: ['center', 'center', 'baseline'],
              marginTop: '10px',
              marginBottom: '20px',
            }}
          >
            <Text
              tag="body-bold"
              sx={{
                color: theme.colors.blackPrimary,
                fontFamily: theme.fonts.grtskExaSemiBold,
                fontSize: '100px',
                lineHeight: '100px',
                fontWeight: '600',
              }}
            >
              $10
            </Text>
            <Text
              tag="body-bold"
              sx={{
                fontFamily: theme.fonts.grtskPeta,
                color: theme.colors.blackPrimary,
                fontSize: ['22px'],
                lineHeight: ['30px'],
                fontWeight: '600',
                letterSpacing: '-0.16px',
              }}
            >
              per month
            </Text>
          </Box>
          <Box>
            <PricingItem text="No hidden fees" />
            <PricingItem text="No minimum balance" />
            <PricingItem text="No commitment" />
            <PricingItem text="Cancel anytime" />
          </Box>

          <Box sx={{ marginY: 20 }}>
            <Text
              tag={'body'}
              sx={{
                fontFamily: theme.fonts.grtskPeta,
                color: theme.colors.blackPrimary,
                fontSize: '16px',
                lineHeight: '22px',
                textAlign: ['center', 'center', 'left', 'left', 'left'],
                display: ['block', 'block', 'none', 'none', 'none'],
              }}
            >
              {"Unlike some bank accounts, \nthere's no charge for"}
            </Text>
            <Text
              tag={'body'}
              sx={{
                fontFamily: theme.fonts.grtskPeta,
                color: theme.colors.blackPrimary,
                fontSize: '16px',
                lineHeight: '22px',
                textAlign: ['center', 'center', 'left', 'left', 'left'],
                display: ['none', 'none', 'block', 'block', 'block'],
              }}
            >
              {"Unlike some bank accounts, there's no charge for"}
            </Text>
            <Text
              tag={'body-bold'}
              sx={{
                fontFamily: theme.fonts.grtskPetaSemiBold,
                color: theme.colors.blackPrimary,
                fontSize: '16px',
                lineHeight: '22px',
                textAlign: ['center', 'center', 'left', 'left', 'left'],
              }}
            >
              {rotatingText[textIndex]}.
            </Text>
          </Box>
          <CTAButton text={'Learn More'} type={'copperPrimary'} url={'/product/pricing'} />
        </Box>
      </HomeMaxWidth>
    </Box>
  )
}

export const PricingItem = ({ text }: IPricingItemProps) => (
  <FlexRowLayout sx={{ marginBottom: '2px' }}>
    <FadeInImage src="static/images/homepage/check-mark.svg" alt="check mark" />
    <Text
      sx={{
        marginLeft: '8px',
        fontFamily: theme.fonts.grtskPetaLight,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '-0.16px',
        color: theme.colors.blackPrimary,
      }}
      tag="body"
    >
      {text}
    </Text>
  </FlexRowLayout>
)
