import React, { useEffect, useState } from 'react'
import { Box } from 'rebass'
import Link from 'next/link'
import { generatePathName } from '../../../helper'
import { IActiveLink } from './dynamic-navbar'
import { theme, Text } from '../../../shared'

const BODY_LARGE_TEXT_STROKE = `0.5px ${theme.colors.blackPrimary}`
const CONTAINER_Z_INDEX = 10
interface ISecondaryNavbarLinkProps {
  link: IActiveLink
  idx: number
  setActiveMenu: (link: IActiveLink) => void
}

export const SecondaryNavbarLink = ({ link, idx, setActiveMenu }: ISecondaryNavbarLinkProps) => {
  const [isCurrentMainPath, setCurrentMainPath] = useState<boolean>()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentMainPath(generatePathName(link.label) === window.location.pathname.split('/')[1])
    }
  }, [])

  const [hoverTextStroke, setHoverTextStroke] = useState<string>()

  const handleOnMouseEnter = () => {
    setHoverTextStroke(BODY_LARGE_TEXT_STROKE)
    setActiveMenu(link)
  }

  return (
    <Box
      sx={{
        marginLeft: [idx === 0 ? 0 : 20, idx === 0 ? 0 : 20, idx === 0 ? 0 : 30],
        marginTop: 1.5,
        cursor: 'pointer',
        zIndex: CONTAINER_Z_INDEX,
      }}
      key={link.label}
    >
      <Link href={`/${generatePathName(link.label)}`} as={`/${generatePathName(link.label)}`}>
        {isCurrentMainPath ? (
          <Box sx={{ zIndex: CONTAINER_Z_INDEX - 1 }}>
            <Text
              tag={'p'}
              sx={{
                WebkitTextStroke: BODY_LARGE_TEXT_STROKE,
                color: theme.colors.blackPrimary,
                ...theme.fontstyles.menu.secondary,
              }}
              onMouseEnter={() => handleOnMouseEnter()}
              onMouseLeave={() => setHoverTextStroke(undefined)}
            >
              {link.label}
            </Text>
          </Box>
        ) : (
          <Text
            tag={'p'}
            sx={{
              WebkitTextStroke: hoverTextStroke,
              color: theme.colors.blackPrimary,
              ...theme.fontstyles.menu.secondary,
              fontSize: [12, 16, 16, 16],
            }}
            onMouseEnter={() => handleOnMouseEnter()}
            onMouseLeave={() => setHoverTextStroke(undefined)}
          >
            {link.label}
          </Text>
        )}
      </Link>
    </Box>
  )
}
