import React from 'react'
import { isExternalURL } from '../../helper'
import { Box, Image, Link, SxStyleProp } from 'rebass'
import { Text, theme } from '../../shared'

interface IPricingBulletProps {
  image: string
  imageDesc: string
  regularText: string
  imgHref?: string
  sx?: SxStyleProp
}

export const PricingBullet = ({ image, imageDesc, regularText, imgHref, sx }: IPricingBulletProps): JSX.Element => (
  <Box sx={{ display: 'flex', alignItems: 'center', ...(sx as SxStyleProp) }}>
    <Link target={isExternalURL(imgHref) ? '_blank' : ''} href={imgHref}>
      <Box sx={{ marginTop: '3px' }}>
        <Image src={image} alt={imageDesc} height="19px" width="19px" />
      </Box>
    </Link>
    <Text
      sx={{
        paddingLeft: '10px',
        fontSize: [theme.fontStyles[2].fontSize, theme.fontStyles[3].fontSize],
        lineHeight: '30px',
        color: theme.colors.ui1,
      }}
    >
      {regularText}
    </Text>
  </Box>
)
