import React from 'react'
import { theme } from '../../shared'
import { Box, Image, Text } from 'rebass'

export const TestimonialCard = ({
  imgPath,
  title,
  text,
  name,
  occupation,
  imageDesc,
}: {
  imgPath: string
  title: string
  text: string
  name: string
  occupation: string
  imageDesc: string
}): JSX.Element => (
  <Box
    sx={{
      bg: theme.colors.white,
      borderRadius: '4px',
      padding: '30px',
      marginX: '10px',
      marginY: [theme.verticalElementSpacing, theme.verticalElementSpacing, 0],
      height: ['330px', '250px', '250px'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
  >
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row', 'row'],
        }}
      >
        <Image
          width="50px"
          height="50px"
          sx={{
            marginRight: '15px',
            marginBottom: '15px',
          }}
          src={imgPath}
          alt={imageDesc}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflowWrap: 'break-word',
            alignItems: 'flex-start',
          }}
        >
          <Text
            sx={{
              color: theme.colors.blackPrimary,
              fontWeight: 'bold',
              ...theme.fontStyles[3],
            }}
          >
            {title}
          </Text>
          <Image
            width={'auto'}
            marginTop="5px"
            height="17px"
            src="/static/images/testimonials/rating.svg"
            alt="five star rating"
          />
        </Box>
      </Box>
      <Text
        sx={{
          color: theme.colors.mediumGrey,
          paddingY: theme.verticalElementSpacing / 2,
        }}
      >
        {text}
      </Text>
    </Box>
    <Text
      sx={{
        color: theme.colors.blackPrimary,
        fontWeight: 'bold',
      }}
    >
      {name}, {occupation}
    </Text>
  </Box>
)
